import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Grid, Typography, Box } from "@mui/material";
import Link from '@mui/material/Link';
import { FaFacebookSquare } from "react-icons/fa";
import { AiOutlineWhatsApp, AiFillInstagram } from "react-icons/ai";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { observer } from "mobx-react-lite";
import { useStore } from "../common/store";

const CURRENCIES = [
  {
    label: 'USD'
  },
  {
    label: 'KZT'
  },
  {
    label: 'RUB'
  }
]
const Navigation = observer(() => {
  const [current, setCurrent] = useState({
    label: 'USD'
  })
  const { carsStore } = useStore();

  useEffect(() => {
    if (carsStore.currencyLabel == null) {
      carsStore.setCurrencyLabel(current.label)
    } else {
      setCurrent(carsStore.currencyLabel)
    }
  })
  const handleCurrency = (c) => {
    setCurrent(c)
    carsStore.setCurrencyLabel(c.label)
    carsStore.loadCurrency('KRW', c.label)
  }

  return (
    <AppBar
      position="absolute"
      className="buttonHidden"
      style={{ backgroundColor: "#1D1D1D", flex: 1, display: 'flex' }}
    >
      <Toolbar sx={{ display: 'flex', flex: 1, flexDirection: 'row', }}>
        <Grid alignContent={"center"} justifyContent={'space-between'} justifyItems={"center"} sx={{ display: 'flex', flex: 1, flexDirection: 'row', marginLeft: '6%' }}>
          <Grid alignContent={"center"} alignItems={'center'} gap={2} sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
            <Link href="/" color="inherit">
              <img src={require('../assets/logoHeader.png')} />
            </Link>
          </Grid>
          <Grid alignContent={"center"} alignItems={'center'} gap={2} sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
            <Link href="/catalog" color="inherit">Каталог авто</Link>
            {/* <a href="/index.php?route=information/information&information_id=7">Условия заказа</a> */}
            {/* <a href="/index.php?route=review/store_review">Отзывы</a> */}
            {/* <a href="/#about1">О нас</a> */}
            {/* <Link href="#" color="inherit">Контакты</Link> */}
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button variant="contained" {...bindTrigger(popupState)}>
                    <Typography variant='h6' color={'white'}>ВАЛЮТА: {carsStore.currencyLabel}</Typography>
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    {
                      CURRENCIES.map(c => {
                        return (
                          <MenuItem onClick={
                            popupState.close
                          }
                            key={c.label}>
                            <Button color={c.label === current.label ? 'success' : 'info'} onClick={() => handleCurrency(c)}>
                              <Typography fontWeight={'600'} color={c.label === current.label ? 'ActiveBorder' : 'GrayText'} variant='h6'>{c.label}</Typography>
                            </Button>
                          </MenuItem>
                        )
                      })
                    }
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Grid>
          <Box>
            <Grid alignContent={"center"} alignItems={'center'} gap={2} sx={{ display: { xs: 'none', sm: 'flex' }, flex: 1.5, flexDirection: 'row', justifyContent: 'flex-end', marginRight: '7%' }}>
              <Link href="https://www.facebook.com/Turan-autokz-114567034467389" color={'inherit'} > <FaFacebookSquare size={22} /></Link>
              <Link href="https://instagram.com/top.auto.korea?igshid=MzRlODBiNWFlZA==" color={'inherit'}  ><AiFillInstagram size={22} /></Link>
              <Link href="https://wa.me/77002019020" color={'inherit'} ><AiOutlineWhatsApp size={22} /> </Link>
              <Grid item xs={8}>
                <Box flexDirection='column' width={200}
                // style={{
                //   display: 'block',
                //   flexDirection: 'column'
                // }}
                >
                  <Link href="tel:+7(700)2019020" color="inherit">+7 (700) 201 9020</Link>
                  <br />
                  {/* <Link href="tel:+821072953574" color="inherit">+8 (210) 7295 3574</Link> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Toolbar>
    </AppBar>
  );
})

export default Navigation