import React, { useEffect } from 'react';
import CarItem from '../components/CarItem';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../common/store';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import { AiOutlineSearch, AiOutlineClose } from 'react-icons/ai';
import { GoSettings } from 'react-icons/go';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Manufactures } from 'constants/dataModels';
import {
	AppBar,
	Toolbar,
	Typography,
	Box,
	Grid,
	Pagination,
	CircularProgress,
	IconButton,
	Button,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const fueltTypes = ['Бензин', 'Дизель', 'Бензин + Электричество', 'Электричество'];



const Catalog = observer(() => {
	const { carsStore } = useStore();
	const navigate = useNavigate();
	const [openDialog, setOpenDialog] = React.useState(false);
	const [openManufacture, setOpenManufacture] = React.useState(false);
	const [valueManufacture, setValueManufacture] = React.useState({
		value: 'Hyundai',
		key: '(C.CarType.Y._.Manufacturer.%ED%98%84%EB%8C%80.))',
	});
	const [openModel, setOpenModel] = React.useState(false);
	const [valueModel, setValueModel] = React.useState('');
	const [valueSubModel, setValueSubModel] = React.useState('');

	const [yearFrom, setYearFrom] = React.useState(2013);
	const [yearTo, setYearTo] = React.useState(2024);

	const [minPrice, setMinPrice] = React.useState(null);
	const [maxPrice, setMaxPrice] = React.useState(null);

	const [fuel, setFuel] = React.useState(fueltTypes);

	const [currentPage, setCurrentPage] = React.useState(1);
	const itemsPerPage = 10;

	const [currencyUSD, setCurrencyUSD] = React.useState(null)

	useEffect(() => {
		if (carsStore.currentManufacture) {
			setValueManufacture(carsStore.currentManufacture);
		} else {
			carsStore.setCurrentManufacture(Manufactures[0]);
		}
		if (carsStore.currentModel) {
			setValueModel(carsStore.currentModel);
		}

		if (carsStore.minPrice) {
			setMinPrice(carsStore.minPrice);
		}
		if (carsStore.maxPrice) {
			setMaxPrice(carsStore.maxPrice);
		}
		if (carsStore.fuelTypes) {
			setFuel(carsStore.fuelTypes);
		}

		carsStore.setYearFrom(yearFrom);
		carsStore.setYearTo(yearTo);

		carsStore.setMinPrice(minPrice);
		carsStore.setMaxPrice(maxPrice);

		carsStore.setFuelTypes(fuel);

		carsStore.loadCars().then(res => {
			// console.log('res', res.SearchResults.length)
		})

		carsStore.loadCurrencyUSD('USD', 'KRW').then(r => {
			setCurrencyUSD(r)
		})

	}, []);

	const handleClickListItemManufacture = () => setOpenManufacture(true);
	const handleClickListItemModel = () => setOpenModel(true);

	const handleCloseManufacture = (newValue) => {
		setOpenManufacture(false);
		if (newValue) setValueManufacture(newValue);
	};

	const handleChangeManufacture = (event) => {
		const selected = Manufactures.filter((m) => m?.value === event.target?.value);
		if (event.target?.value !== valueManufacture.value) {
			setValueModel('');
			setValueSubModel('');
		}
		setValueManufacture(selected[0]);
		handleCloseManufacture(selected[0]);
		setValueModel(null);
	};

	const handleCloseModel = () => {
		setOpenModel(false);
	};

	const handleSave = () => {
		if (fuel.length > 0) {
			carsStore.resetPages();
			carsStore.setCurrentManufacture(valueManufacture);
			carsStore.setCurrentModel(valueModel || valueSubModel?.subModel);
			carsStore.loadCars();
			handleCloseDialog();
		} else alert('Поле "топлива" не может быть пустым');
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handlePage = (event, value) => {
		carsStore.setSelectedPage(value);
		carsStore.changePages();
		carsStore.loadCars();
	};
	const handleChangeYearFrom = (event) => {
		setYearFrom(event.target.value);
		carsStore.setYearFrom(event.target.value);
	};
	const handleChangeYearTo = (event) => {
		setYearTo(event.target.value);
		carsStore.setYearTo(event.target.value);
	};

	const handleMinPrice = (event) => {
		setMinPrice(event.target.value);
		carsStore.setMinPrice(event.target.value);
	};
	const handleMaxPrice = (event) => {
		setMaxPrice(event.target.value);
		carsStore.setMaxPrice(event.target.value);
	};

	const handleChangeFuel = (event) => {
		const {
			target: { value },
		} = event;
		setFuel(value);
		carsStore.setFuelTypes(value);
	};

	const dataModels = Manufactures.filter((m) => m?.value === valueManufacture.value)[0]?.models;

	const onSelectModel = (option) => {
		const selected = Manufactures.filter(
			(m) => m?.value === valueManufacture.value
		)[0]?.models?.filter((m) => m?.value === option.value);
		setValueModel(selected[0]);
		handleCloseModel();
		setValueSubModel('');
	};

	const onSelectSubModel = (option, subModel) => {
		setValueSubModel({ option, subModel });
		setOpenModel(false);
		handleCloseModel();
		setValueModel('');
	};

	const model = carsStore.currentModel ? carsStore.currentModel : carsStore.currentManufacture;

	var data = [...new Set(carsStore?.carsList)];

	var fuelTypesFiltered = fuel
		?.map((element: string) => {
			if (element === 'Бензин') {
				return '가솔린';
			} else if (element === 'Дизель') {
				return '디젤';
			} else if (element === 'Бензин + Электричество') {
				return '가솔린+전기';
			} else if (element === 'Электричество') {
				return '전기';
			}
		})

	var data = [...new Set(carsStore?.carsList)]
		.filter(car => {
			const carPrice = Math.round(car.Price / currencyUSD * 10000);
			const carYear = Math.floor(car.Year / 100); // Convert 202101.0 to 2021

			return (
				(!minPrice || carPrice >= minPrice) &&
				(!maxPrice || carPrice <= maxPrice) &&
				(!fuelTypesFiltered || fuelTypesFiltered.includes(car.FuelType)) &&
				(!yearFrom || carYear >= yearFrom) &&
				(!yearTo || carYear <= yearTo)
			);
		});

	const startIdx = (currentPage - 1) * itemsPerPage;
	const endIdx = startIdx + itemsPerPage;
	const displayedData = data.slice(startIdx, endIdx);



	return (
		<Box>
			{/* Publications */}
			<Grid
				alignContent={'center'}
				justifyContent={'center'}
				mb={1}
				justifyItems={'center'}
				sx={{ display: 'flex' }}
			>
				<Box
					onClick={() => {
						setOpenDialog(true);
					}}
					flexDirection={'row'}
					flex={1}
					sx={{
						justifyContent: 'space-between',
						display: 'flex',
						maxWidth: 700,
						marginBottom: 20,
						borderColor: 'grey.500',
						borderWidth: 1,
						bgcolor: 'background.paper',
						m: 1,
						border: 1,
						borderRadius: 10,
					}}
				>
					<Box flexDirection={'row'} flex={1} sx={{ display: 'flex' }}>
						<AiOutlineSearch size={30} style={{ marginLeft: 15, marginTop: 5 }} />
						<Typography sx={{ marginTop: 1, marginLeft: 1 }} variant={'h5'}>
							{data?.length} публикации ждут вас
						</Typography>
					</Box>
					<GoSettings size={30} style={{ marginRight: 15, marginTop: 5 }} />
				</Box>
			</Grid>

			{/* Cars */}
			<Grid
				container
				spacing={2}
				alignContent={'center'}
				justifyContent={'center'}
				justifyItems={'center'}
			>
				{carsStore.isLoadingList ? (
					<Grid mt={2}>
						<CircularProgress />
						<Box bgcolor="rgba(0, 0, 0, 0.1)" position="absolute" top={0} left={0} right={0} bottom={0} display="flex" alignItems="center" justifyContent="center">
							<Typography style={{ color: 'grey' }} fontSize={18}>
								Загрузка может занять 1-2 минуты.
								Пожалуйста, подождите
							</Typography>
						</Box>
					</Grid>
				) : (
					displayedData?.map((car) => (
						<Grid item key={car.id + car.badDetail}>
							<Box
								onClick={() => {
									navigate(`/car/${car.Id}`, { state: car });
								}}
							>
								<CarItem car={car} currency={carsStore.currency} />
							</Box>
						</Grid>
					))
				)}
			</Grid>

			<br />

			{/* Pagination */}
			<Grid container justifyContent='center'>
				<Pagination
					sx={{ fontSize: 16, mb: 5 }}
					color='primary'
					variant='text'
					onChange={(event, value) => { setCurrentPage(value); handlePage(event, value); }}
					count={Math.ceil(data?.length / itemsPerPage)}
					page={currentPage}
					size='large'
				/>
			</Grid>

			{/* Filter */}
			<>
				<Dialog open={openDialog}>
					<AppBar sx={{ position: 'relative' }} style={{ backgroundColor: 'black' }}>
						<Toolbar>
							<IconButton
								edge='start'
								color='inherit'
								onClick={handleCloseDialog}
								aria-label='close'
							>
								<AiOutlineClose size={30} />
							</IconButton>
							<Typography sx={{ ml: 2, flex: 1 }} variant={'h5'} component='div'>
								Фильтр
							</Typography>
							<Button autoFocus color='inherit' onClick={handleSave}>
								<Typography variant={'h5'} color={'white'}>
									Сохранить
								</Typography>
							</Button>
						</Toolbar>
					</AppBar>
					<List sx={{ padding: 3, borderRadius: 4 }}>
						<Dialog
							onClickCapture={() => setOpenManufacture(false)}
							sx={{ '& .MuiDialog-paper1': { width: '80%', maxHeight: 435 } }}
							maxWidth='xs'
							open={openManufacture}
						>
							<DialogTitle variant='h5'>Производственная компания</DialogTitle>
							<DialogContent dividers>
								<RadioGroup
									aria-label='ringtone'
									name='ringtone'
									value={valueManufacture?.value}
									onChange={handleChangeManufacture}
								>
									{Manufactures.map((option) => (
										<FormControlLabel
											value={option?.value}
											key={option.key + option.value}
											control={<Radio size={'medium'} />}
											label={<Typography variant={'h4'}>{option?.value}</Typography>}
										/>
									))}
								</RadioGroup>
							</DialogContent>
						</Dialog>
						<Dialog
							sx={{ '& .MuiDialog-paper2': { flex: 1, width: '80%', maxHeight: 435 } }}
							maxWidth='lg'
							open={openModel}
						>
							<Box flex={1} flexDirection={'row'} m={2} minWidth={200}>
								<Typography variant='h5'>Выберите модель</Typography>{' '}
								<IconButton
									aria-label='close'
									onClick={() => {
										handleCloseModel();
									}}
									sx={{
										position: 'absolute',
										right: 0,
										top: 5,
										color: (theme) => theme.palette.grey[500],
									}}
								>
									<CloseIcon fontSize={'large'} />
								</IconButton>
							</Box>

							<DialogContent dividers>
								<TreeView
									aria-label='file system navigator'
									defaultCollapseIcon={<ExpandMoreIcon />}
									defaultExpandIcon={<ChevronRightIcon />}
									sx={{ flexGrow: 1, overflowY: 'auto' }}
								>
									{dataModels.map((option) => {
										return (
											<TreeItem
												sx={{ marginY: 1 }}
												key={option.value + option.key}
												onClick={() => (option?.subModels ? {} : onSelectModel(option))}
												nodeId={option.value + option.key}
												label={<Typography variant={'h4'}>{option.value}</Typography>}
											>
												{option?.subModels &&
													option?.subModels?.map((sub) => {
														return (
															<TreeItem
																sx={{ marginY: 1 }}
																onClick={() => onSelectSubModel(option, sub)}
																nodeId={sub.key + sub.value}
																key={sub.key + sub.value}
																label={<Typography variant={'h4'}>{sub.value}</Typography>}
															/>
														);
													})}
											</TreeItem>
										);
									})}
								</TreeView>
							</DialogContent>
						</Dialog>
						<ListItem
							button
							divider
							aria-haspopup='true'
							aria-controls='ringtone-menu'
							aria-label='phone ringtone'
							onClick={handleClickListItemManufacture}
						>
							<ListItemText
								primary={
									<Typography color={'GrayText'} variant={'h5'}>
										{' '}
										Производственная компания
									</Typography>
								}
								secondary={<Typography variant={'h5'}>{valueManufacture?.value}</Typography>}
							/>
						</ListItem>
						<ListItem
							button
							divider
							aria-haspopup='true'
							aria-controls='ringtone-menu'
							aria-label='phone ringtone'
							onClick={handleClickListItemModel}
						>
							<ListItemText
								primary={
									<Typography color={'GrayText'} variant={'h5'}>
										Модель
									</Typography>
								}
								secondary={
									<Typography variant={'h5'}>
										{valueModel?.value || valueSubModel?.subModel?.value}{' '}
									</Typography>
								}
							/>
						</ListItem>
						<Grid flexDirection={'row'} display={'flex'} alignItems={'center'} mt={1}>
							<TextField
								label={
									<Typography color={'GrayText'} variant={'h5'}>
										Минимальная цена
									</Typography>
								}
								size={'medium'}
								variant={'outlined'}
								onChange={handleMinPrice}
								value={minPrice}
								id='outlined-start-adornment'
								sx={{ m: 1, width: '25ch' }}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<Typography color={'GrayText'} variant={'h5'}>
												$
											</Typography>
										</InputAdornment>
									),
									style: { fontSize: 14 },
								}}
							/>
							<Typography color={'GrayText'} variant={'h5'}>
								{' '}
								–{' '}
							</Typography>
							<TextField
								label={
									<Typography color={'GrayText'} variant={'h5'}>
										Максимальная цена
									</Typography>
								}
								id='outlined-start-adornment'
								sx={{ m: 1, width: '25ch' }}
								onChange={handleMaxPrice}
								value={maxPrice}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<Typography color={'GrayText'} variant={'h5'}>
												$
											</Typography>
										</InputAdornment>
									),
									style: { fontSize: 14 },
								}}
							/>
						</Grid>
						<Box
							component='form'
							mt={1}
							sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
						>
							<FormControl variant={'outlined'} sx={{ m: 1, minWidth: 150 }}>
								<InputLabel htmlFor='demo-dialog-native'>
									<Typography color={'GrayText'} variant={'h5'}>
										От
									</Typography>
								</InputLabel>
								<Select
									native
									value={yearFrom}
									size={'medium'}
									onChange={handleChangeYearFrom}
									input={<OutlinedInput label='От' id='demo-dialog-native' />}
								>
									<option aria-label='None' value='' />
									{/* <option value={2019}>2019</option>
									<option value={2020}>2020</option> */}
									<option value={2013}>2013</option>
									<option value={2014}>2014</option>
									<option value={2015}>2015</option>
									<option value={2016}>2016</option>
									<option value={2017}>2017</option>
									<option value={2018}>2018</option>
									<option value={2019}>2019</option>
									<option value={2020}>2020</option>
									<option value={2021}>2021</option>
									<option value={2022}>2022</option>
									<option value={2023}>2023</option>
									<option value={2024}>2024</option>
								</Select>
							</FormControl>
							<Typography> – </Typography>
							<FormControl sx={{ m: 1, minWidth: 150 }}>
								<InputLabel id='demo-dialog-select-label'>
									<Typography color={'GrayText'} variant={'h5'}>
										До
									</Typography>
								</InputLabel>
								<Select
									value={yearTo}
									onChange={handleChangeYearTo}
									native
									input={<OutlinedInput label='Age' id='demo-dialog-native' />}
								>
									<option aria-label='None' value='' />
									{/* <option value={2019}>2019</option>
									<option value={2020}>2020</option> */}
									<option value={2013}>2013</option>
									<option value={2014}>2014</option>
									<option value={2015}>2015</option>
									<option value={2016}>2016</option>
									<option value={2017}>2017</option>
									<option value={2018}>2018</option>
									<option value={2019}>2019</option>
									<option value={2020}>2020</option>
									<option value={2021}>2021</option>
									<option value={2022}>2022</option>
									<option value={2023}>2023</option>
									<option value={2024}>2024</option>
								</Select>
							</FormControl>
						</Box>
						<FormControl sx={{ m: 1, width: 400 }}>
							<InputLabel sx={{ width: 400 }} id='demo-multiple-checkbox-label'>
								<Typography color={'GrayText'} variant={'h5'}>
									Топлива
								</Typography>
							</InputLabel>
							<Select
								labelId='demo-multiple-checkbox-label'
								id='demo-multiple-checkbox'
								multiple
								value={fuel}
								onChange={handleChangeFuel}
								input={<OutlinedInput label='Топлива' />}
								renderValue={(selected) => selected.join(', ')}
								MenuProps={MenuProps}
							>
								{fueltTypes.map((name) => (
									<MenuItem key={name} value={name}>
										<Checkbox checked={fuel?.indexOf(name) > -1} />
										<ListItemText primary={<Typography variant={'h5'}>{name}</Typography>} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</List>
				</Dialog>
				<Button
					onClick={() => {
						setOpenDialog(true);
					}}
					size='large'
					variant='contained'
					startIcon={<FilterListIcon />}
					sx={{
						bottom: 15,
						right: 20,
						marginTop: 5,
						fontSize: 14,
						position: 'fixed',
						backgroundColor: 'black',
					}}
				/>
			</>
		</Box>
	);
});

export default Catalog;
