import React from "react";
import { Typography, Link } from "@mui/material";

const Copyright = () => {
  return (
    <Typography mb={2.5} variant='h6' color="textSecondary" align="center">
      {/* <Link
        color="inherit"
        href="https://www.instagram.com/turan_auto.kz/?hl=ru"
      >
        {"Copyright © "} Turan-Auto Cars {new Date().getFullYear()}
      </Link> */}
    </Typography>
  );
};

export default function Footer() {
  return (
    <div>
      <Typography variant="h6" align="center" mt={3}>
        {/* Turan-Auto Cars */}
      </Typography>
      <Copyright />
    </div>
  );
}
