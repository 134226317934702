import { SERVER } from '../../constants';
import { responseHandler } from '../../utils';
import { dump } from './dump';
import { Manufactures } from 'constants/dataModels';

const { BASE_URL } = SERVER;

const fetchCarsList = async (
	itemsPerPage: number,
	nextPage: number,
	currentManufacture?: string,
	currentModel?: string,
	yearFrom?: string,
	yearTo?: string,
	minPrice?: number,
	maxPrice?: number,
	fuelTypes?: any
) => {
	const priceRange = `_.Price.range(${Math.round(minPrice * 1426.39)
		.toString()
		.slice(0, -4)}..${Math.round(maxPrice * 1426.39)
			.toString()
			.slice(0, -4)}).)`;
	const model = currentModel ? currentModel : currentManufacture;

	var fuelTypesFiltered = fuelTypes
		?.map((element: string) => {
			if (element === 'Бензин') {
				return 'FuelType.%EA%B0%80%EC%86%94%EB%A6%B0';
			} else if (element === 'Дизель') {
				return 'FuelType.%EB%94%94%EC%A0%A4';
			} else if (element === 'Бензин + Электричество') {
				return 'FuelType.%EA%B0%80%EC%86%94%EB%A6%B0%2B%EC%A0%84%EA%B8%B0';
			} else if (element === 'Электричество') {
				return 'FuelType.%EC%A0%84%EA%B8%B0';
			}
		})
		?.join('._.');

	const key = `${minPrice && maxPrice ? model.slice(0, -1) + priceRange : model}`;

	const url = `${BASE_URL}?count=true&q=(And.Hidden.N._.(Or.Transmission.%EC%98%A4%ED%86%A0._.Transmission.CVT.)_.Separation.B._.SellType.%EC%9D%BC%EB%B0%98._.(Or.${fuelTypesFiltered}.)_.Year.range(${yearFrom}00..${yearTo}99)._.${key}&sr=%7CModifiedDate%7C${nextPage}%7C100&inav=%7CMetadata%7CSort`

	// const browser = navigator.userAgent
	// console.log("userAgentString", browser)

	// function getBrowserType(userAgentString) {
	// 	console.log("userAgentString", userAgentString)
	// 	if (userAgentString.includes('Chrome')) {
	// 		return 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36';
	// 	} else if (userAgentString.includes('Safari')) {
	// 		return 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.5.2 Safari/605.1.15';
	// 	} else if (userAgentString.includes('Firefox')) {
	// 		return 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:109.0) Gecko/20100101 Firefox/114.0';
	// 	} else if (userAgentString.includes('Edge')) {
	// 		return 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36 Edg/114.0.1823.37';
	// 	} else {
	// 		return 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36';
	// 	}
	// }

	const body = {
		url: url,
		// ...other data fields...
	};

	const findMatch = (arr, searchKey) => {
		for (const manufacturer of arr) {
			if (manufacturer.key === searchKey) return manufacturer.value;

			if (manufacturer.models) {
				for (const model of manufacturer.models) {
					if (model.key === searchKey) return model.value;

					if (model.subModels) {
						for (const subModel of model.subModels) {
							if (subModel.key === searchKey) return subModel.value;
						}
					}
				}
			}
		}
		return null;
	};

	const searchKey = model
	const matchedValue = findMatch(Manufactures, searchKey);

	const baseURl = `https://turan-auto.zhasapp.com/manufacture?value=${matchedValue}`;

	const response = await fetch(baseURl, {
		method: 'GET',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
		// body: JSON.stringify(body),
	});

	// handle response
	const result = await response.json();
	return result;
};

const fetchCurrentCar = async (carId: string) => {
	const params = {
		method: 'GET',
	};

	const url = `https://api.encar.com/v1/readside/vehicle/${carId}?include=ADVERTISEMENT,CATEGORY,CONDITION,CONTACT,MANAGE,OPTIONS,PHOTOS,SPEC,PARTNERSHIP`;
	const response = await fetch(url, params);
	const result = await responseHandler<any>(response);
	return result;
};

const fetchCurrentCarInspection = async (carId: string) => {
	const params = {
		method: 'GET',
	};
	const url = `https://api.encar.com/v1/readside/inspection/vehicle/${carId}`;
	const response = await fetch(url, params);
	const result = await responseHandler<any>(response);
	return result;
};

const fetchCurrency = async (currencyFrom, currencyTo) => {
	const params = {
		method: 'GET',
	};
	const url = `https://api.exchangerate.host/convert?from=${currencyFrom}&to=${currencyTo}`;
	const response = await fetch(url, params);
	const result = await responseHandler<any>(response);
	return result;
};

export { fetchCarsList, fetchCurrency, fetchCurrentCar, fetchCurrentCarInspection };
