import React, { useState, useEffect } from "react";
import {
  Card, rgbToHex, Box
} from "@mui/material";
import Carousel from 'react-gallery-carousel';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    position: 'absolute',
    top: '50%',
    left: 0,
    display: 'block',
    transform: 'translateY(-50%)',
  },

}));


const images = [
  {
    image: require('../assets/slider/1.png')
  },
  {
    image: require('../assets/slider/2.png')
  },
  {
    image: require('../assets/slider/3.png')
  },
  {
    image: require('../assets/slider/4.png')
  },
  {
    image: require('../assets/slider/5.png')
  },
  {
    image: require('../assets/slider/6.png')
  },
  {
    image: require('../assets/slider/7.png')
  },
  {
    image: require('../assets/slider/8.png')
  },
  {
    image: require('../assets/slider/9.png')
  },
]


export default function Sliders() {



  const classes = useStyles();

  return (
    <Box sx={{
      mt: 4, mb: 3, position: 'relative',
      flex: 1, display: 'flex', width: '100%'
    }} >
      <Carousel
        isLoop={true}
        isAutoPlaying={true}
        autoPlayInterval={3000}
        transitionSpeed={6000}
        // transitionDurationMin={0.003}
        // transitionDurationMax={0.004}
        shouldLazyLoad={true}
        canAutoPlay={true}
        hasTransition={true}
        hasSizeButton={false}
        hasIndexBoard={false}
        hasMediaButton={false}
        hasThumbnails={false}
        // objectFit='scale-down'
        rightIcon={false}
        leftIcon={false}
        // objectFitAtMax='contain'
        className='carouselhero'
        images={images?.map((item) => {
          return {
            src: item.image,
          }
        })}
      />
      <div className={classes.buttonContainer}>
        <div className="container">
          <div className="row">
            <div className="bg1_txt">
              <a href="/catalog" className="main_btn">
                <i className="main_btn_icon"></i>Подобрать авто
              </a>
              <a href="/catalog" className="buttonHidden">
                <button type="button" className="main_btn_mob_filter buttonHidden" data-ocf="mobile" aria-label="Фильтр">
                  <i className="main_btn_icon"></i> Подобрать авто
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
