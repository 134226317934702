import carsStore, { CarsStore } from "./carsStore";

export type RootStore = {
  carsStore: CarsStore;
};

const rootStore: RootStore = {
  carsStore,
};

export default rootStore;
