import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import carsStore from "stores/carsStore";
import translate from "translate";
import { ProgressBar } from 'react-loader-spinner'


export default function RecipeReviewCard({ car, currency }) {
  const {
    Model,
    Mileage,
    Badge,
    FormYear,
    FuelType,
    Year,
    Photo,
    Price,
    BadgeDetail,
  } = car;
  const [headerTitle, setHeaderTitle] = useState('');

  useEffect(() => {
    handleTranslation(`${Model !== undefined ? Model : ""} ${Badge !== undefined ? `,${Badge}` : ""} 
    ${BadgeDetail !== undefined ? `,${BadgeDetail}` : ""} ${FuelType !== undefined ? `,${FuelType}` : ""}`)
      .then(res => {
        setHeaderTitle(res)
      })
  }, []);

  const handleTranslation = async (text) => {
    const translation = await translate(text, { from: 'ko', to: 'ru', cache: 1010323220010000 })
    return await translation
  }

  return (
    <Card sx={{ maxWidth: 350, borderRadius: 3 }}>
      <Box
        justifyContent={'center'}
        component="img"
        sx={{
          objectFit: 'cover',
          width: 350,
          height: 208,
        }}
        alt="xponend.com zhasapp.com sultandelux"
        src={`https://ci.encar.com/carpicture${Photo?.trim()}001.jpg`}
      />
      <CardContent>
        <Typography
          fontWeight={500}

          style={{ wordWrap: "break-word" }}
          sx={{
            contain: "content", display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
          }}
          variant='h6'
          maxWidth={380}
        >
          {headerTitle}
        </Typography>
        <Typography variant='h6' color={'GrayText'}>
          Год выпуска: {FormYear}, Пробег:{" "} {Mileage?.toLocaleString()?.replace(",", " ")} км
          {/* {(Number(mileage) / 1000).toFixed(2)} км */}
        </Typography>
        <Typography
          // textAlign={"right"}
          color="#CA1F1F"
          variant="h6"
        >
          {currency
            ? (Number(Price) * currency * 10000)
              ?.toLocaleString()
              ?.split(".")[0]
            : (Number(Price) * 0.00075 * 10000)
              ?.toLocaleString()
              ?.split(".")[0]}{" "}
          {carsStore.currencyLabel}
        </Typography>
      </CardContent>
    </Card>
  );
}
