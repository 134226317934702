import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const Stores = () => {

  const handleClickAppStore = () => {
    window.open("https://apps.apple.com/us/app/turan-auto/id6443944522");
  };
  const handleClickGooglePlay = () => {
    window.open("https://play.google.com/store/apps/details?id=com.zhasapp.turanauto.android");
  };

  return (
    <Grid className="app-stores buttonHidden" container justifyContent={'center'} columns={{ xs: 5, sm: 8, md: 12 }}>
      <Grid item xs={6} sm={6} md={4} >
        <Typography textAlign={'center'} variant='h3' mb={0.4}>Скачайте Turan-Auto в AppStore и Play Store!
        </Typography>
        <Grid container gap={2} mt={2} justifyContent={'center'} flex justifySelf={'center'}>
          <Grid item justifyContent={'center'} display='flex' container>
            <Grid container columns={{ xs: 2, sm: 10, md: 12 }}>
              <Grid item xs={6} justifyContent={'center'} display='flex' >
                <Box
                  justifyContent={'center'}
                  component="img"
                  sx={{
                    objectFit: 'cover',
                    width: 200,
                    marginRight: 2
                  }}
                  alt="xponend.com zhasapp.com sultandelux"
                  src={require('../assets/badgeios.png')}
                  onClick={handleClickAppStore}
                />
              </Grid>
              <Grid item xs={6} justifyContent={'center'} display='flex'>
                <Box
                  component="img"
                  sx={{
                    objectFit: 'contain',
                    width: 180,
                    // overflow: 'visible',
                    display: 'block',
                    marginRight: 2
                  }}
                  alt="xponend.com zhasapp.com sultandelux"
                  src={require('../assets/badgeandroid.png')}
                  onClick={handleClickGooglePlay}
                />
              </Grid>
            </Grid>

          </Grid>
          <Grid item justifyContent={'center'} display='flex' >
            <Box
              justifyContent={'center'}
              component="img"
              sx={{
                objectFit: 'cover',
                width: 200,
                height: 220,
                marginTop: 1,
                marginRight: 2
              }}
              alt="xponend.com zhasapp.com sultandelux"
              src={require('../assets/qr.png')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={6} md={6} sx={{ display: { xs: "none", lg: "block" } }} >
        <Box
          justifyContent={'center'}
          alignContent={'center'}
          component="img"
          sx={{}}
          alt="xponend.com zhasapp.com sultandelux"
          src={require('../assets/app.png')}
        />
      </Grid>
    </Grid>
  )
}

export default Stores