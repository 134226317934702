import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

export default function VideoList({ data }) {
  return (
    <Box sx={{ flexGrow: 1, }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={2} sm={4} md={4} >
          <Box sx={{ backgroundColor: '#195ecd', padding: 1 }} borderRadius={2.5}>
            <LiteYouTubeEmbed
              id="z3UjpHnKYp4"
              title="Ең арзан бағада Hyundai Palisade сатылды! 17.4 млн тг под ключ. 2020ж 3.8 бензин 295 л.с. Exclusive"
            />
          </Box>
          <Box sx={{ backgroundColor: 'black', padding: 1 }} mt={1} borderRadius={2.5}>
            <Typography variant='h5' mt={1} color={'white'} >
              Ең арзан бағада Hyundai Palisade сатылды! 17.4 млн тг под ключ
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} sm={4} md={4} >
          <Box sx={{ backgroundColor: '#195ecd', padding: 1 }} borderRadius={2.5}>
            <LiteYouTubeEmbed
              id="2SrdPvVUTfo"
              title=" Toyota sienna көлігі сатылды! Алматыға дейін 16.2 млн тг. Made in japan."
            />
          </Box>
          <Box sx={{ backgroundColor: 'black', padding: 1 }} mt={1} borderRadius={2.5}>
            <Typography variant='h5' mt={1} color={'white'} >
              Toyota sienna көлігі сатылды! Алматыға дейін 16.2 млн тг. Made in japan.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} sm={4} md={4} display={{ xs: "none", lg: "block", md: "block"  }}>
          <Box sx={{ backgroundColor: '#195ecd', padding: 1 }} borderRadius={2.5}>
            <LiteYouTubeEmbed
              id="675o3-a6Lrw"
              title="Kia K7 керемет бағада 🔥🔥🔥 Кореядан Қазақстанға 25 күнде!
              "
            />
          </Box>
          <Box sx={{ backgroundColor: 'black', padding: 1 }} mt={1} borderRadius={2.5}>
            <Typography variant='h5' mt={1} color={'white'} >
              Kia K7 керемет бағада 🔥🔥🔥 Кореядан Қазақстанға 25 күнде!
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}