import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import { useParams, useLocation, Link as RouterLink } from "react-router-dom";
import { observer } from "mobx-react";
import { useStore } from "../common/store";
import { Translate } from "react-auto-translate";
import { TbPhoneCall } from "react-icons/tb";
import { AiOutlineWhatsApp } from "react-icons/ai";
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import translate from "translate";
import { ProgressBar } from 'react-loader-spinner'

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const Car = observer(() => {
	const { key: carId } = useParams();
	const { carsStore } = useStore();
	const [headerTitle, setHeaderTitle] = useState('');
	const [fuel, setfuel] = useState('');
	const [transmission, setTransmission] = useState('');

	React.useEffect(() => {
		carsStore.getCurrentCar(carId);
		carsStore.getCurrentCarInspection(carId);
	}, []);

	useEffect(() => {
		if (carsStore.currentCar) {
			handleTranslation(`${carsStore?.currentCar?.category !== undefined ? carsStore?.currentCar?.category?.modelName : ""}, 
				${carsStore.currentCar?.category?.gradeName},  ${carsStore?.currentCar?.category?.manufacturerEnglishName}`).then(res => {
				setHeaderTitle(res)
			})
		}
	}, [carsStore?.currentCar]);

	useEffect(() => {
		if (carsStore?.currentCar?.spec?.fuelName) {
			handleTranslation(`${carsStore?.currentCar?.spec?.fuelName}`).then(res => {
				setfuel(res)
			})
		}
		if (carsStore?.currentCar?.spec?.transmissionName) {
			handleTranslation(`${carsStore?.currentCar?.spec?.transmissionName}`).then(res => {
				setTransmission(res)
				if (carsStore?.currentCar?.spec?.transmissionName == '오토') {
					setTransmission('Автомат')
				}
			})
		}
	}, [carsStore?.currentCar]);

	const handleTranslation = async (text) => {
		const translation = await translate(text, { from: 'ko', to: 'ru', cache: 123230000000000 })
		return await translation
	}

	const onStatusType = (value) => {
		var statusType;
		switch (value) {
			case 'X':
				return statusType = 'Замена:'
			case 'W':
				return statusType = 'Сварка:'
			case 'C':
				return statusType = 'Коррозия:'
			case 'A':
				return statusType = 'Царапина:'
			case 'U':
				return statusType = 'Нарушение:'
			case 'T':
				return statusType = 'Повреждение:'
		}
	}
	const onColorStatus = (value) => {
		var statusType
		switch (value) {
			case 'X':
				return statusType = '#FF2626'
			case 'W':
				return statusType = '#649EDE'
			case 'C':
				return statusType = '#E7912A'
			case 'A':
				return statusType = '#7288A0'
			case 'U':
				return statusType = '#737761'
			case 'T':
				return statusType = '#A58174'
		}
	}
	if (carsStore?.currentCar) {
		return (
			<Container maxWidth="lg">
				{/* <Button color='info' variant='contained' onClick={() => navigate(-1)}>
				<AiOutlineArrowLeft size={20} />	<Typography ml={1} variant='h6' color='white'>Назад в Каталог</Typography>
			</Button> */}
				<Typography variant="h4" sx={{ MozBackgroundInlinePolicy: 2, textAlign: 'center', mb: 3 }}>
					{headerTitle} {carsStore?.currentCar?.category?.formYear}
				</Typography>
				{carsStore.currentCar && (
					<Grid container spacing={1} sx={{ alignContent: 'center', alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }} alignSelf='center' columns={{ xs: 16, md: 16, }}>
						<Grid item md={8} xs={16}>
							<Box sx={{ display: { xs: 'none', md: 'block' } }}>
								<Item>
									<Carousel images={carsStore?.currentCar?.photos?.slice()?.sort((a, b) => a?.code - b?.code)?.map((item) => {
										return {
											src: `https://ci.encar.com${item?.path}`,
										}
									})}
										style={{ height: 360 }} />
								</Item>
							</Box>
						</Grid>
						<Grid item md={8} mt={1}>
							<Box sx={{
								display: {
									xs: 'block', md: 'none',
									alignContent: 'center', justifyContent: 'flex-start', alignItems: 'flex-start'
								}
							}}>
								<Item>
									<Carousel images={carsStore?.currentCar?.photos?.slice()?.sort((a, b) => a?.code - b?.code)?.map((item) => {
										return {
											src: `https://ci.encar.com${item?.path}`,
										}
									})}
										style={{ height: 250 }} />
								</Item>
							</Box>
							<Item>
								<Box >
									<Grid container flexDirection='row' spacing={2} columns={16}>
										<Grid item xs={8}>
											<Typography variant='h5' fontWeight={700} component="p" align='left'>
												Стоимость:
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography color="#CA1F1F" variant='h5' component="p" align='left' fontWeight={'600'}  >
												{carsStore?.currency
													? (Number(carsStore?.currentCar?.advertisement?.price) * carsStore?.currency * 10000)
														?.toLocaleString()
														?.split(".")[0]
													: (Number(carsStore?.currentCar?.advertisement?.price) * 0.00075 * 10000)
														?.toLocaleString()
														?.split(".")[0]}{" "}
												{carsStore.currencyLabel}
											</Typography>
										</Grid>
									</Grid>
									<Grid container flex={1} display={'flex'} direction='row' spacing={2} columns={16}>
										<Grid item xs={8}>
											<Typography variant='h5' fontWeight={700} component="p" align='left'>
												Год выпуска:
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant='h5' component="p" align='left'>
												{carsStore?.currentCar?.category?.formYear}
											</Typography>
										</Grid>
									</Grid>

									<Grid container flex={1} display={'flex'} direction='row' spacing={2} columns={16}>
										<Grid item xs={8}>
											<Typography variant='h5' fontWeight={700} component="p" align='left'>
												Пробег:
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant='h5' fontWeight={700} component="p" align='left'>
												{/* {(Number(carsStore?.currentCar?.spec?.mileage) / 1000).toFixed(2)} */}
												{carsStore?.currentCar?.spec?.mileage?.toLocaleString()?.replace(",", " ")} км
											</Typography>

										</Grid>
									</Grid>
									{/* <Grid container flex={1} display={'flex'} direction='row' spacing={2} columns={16}> */}
									{/* <Grid item xs={8}>
											<Typography variant='h5' fontWeight={700} component="p" align='left'>
												Цвет:
											</Typography>
										</Grid> */}
									{/* <Grid item xs={8}>
											<Typography variant='h5' fontWeight={700} component="p" align='left'>
												<Translate>{carsStore?.currentCar?.spec?.colorName}</Translate>
											</Typography>
										</Grid> */}

									{/* </Grid> */}
									<Grid container flex={1} display={'flex'} direction='row' spacing={2} columns={16}>
										<Grid item xs={8}>
											<Typography variant='h5' fontWeight={700} component="p" align='left'>
												Топливо:
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant='h5' fontWeight={700} component="p" align='left'>
												{fuel}
											</Typography>
										</Grid>

									</Grid>
									<Grid container flex={1} display={'flex'} direction='row' spacing={2} columns={16}>
										<Grid item xs={8}>
											<Typography variant='h5' fontWeight={700} component="p" align='left'>
												Коробка передач:
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant='h5' fontWeight={700} component="p" align='left'>
												{transmission}
											</Typography>
										</Grid>
									</Grid >
									<Grid container flex={1} display={'flex'} direction='row' spacing={2} columns={16}>
										<Grid item xs={8}>
											<Typography variant='h5' fontWeight={700} component="p" align='left'>
												Диагностика:
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant='h5' fontWeight={700} component="p" align='left'>
												{carsStore?.currentCarInspection?.master?.accident ? 'Аварийный' : 'Безаварийный'}
											</Typography>
										</Grid>
									</Grid>
								</Box ></Item >
							<Grid justifyContent={'center'} gap={2} display='flex' flex={1} justifyItems='center' alignItems={'center'}>
								<Button href="tel:+77002019020" size='large' variant="contained" startIcon={<TbPhoneCall />} color={'info'} sx={{ marginTop: 5, fontSize: 16 }}>
									ЗАКАЗАТЬ
								</Button>
								<Button href={`https://wa.me/77002019020?text=Добрый%20день!%20Интересует%20${carsStore?.currentCar?.category?.manufacturerEnglishName}%20${carsStore?.currentCar?.category?.formYear}%20года%20выпуска%20https://Turan-Auto.com/car/${carId}`} size='large' variant="contained"
									startIcon={<AiOutlineWhatsApp />} color={'success'} sx={{ marginTop: 5, fontSize: 16 }}>
									НАПИСАТЬ
								</Button>
							</Grid>
						</Grid >
					</Grid >
				)}
				<Box mt={2} >
					{carsStore?.currentCarInspection?.outers?.map((el, index) => {
						return (
							<Grid container spacing={2} columns={16} key={index + el?.type?.title} alignItems={'left'} justifyContent={'left'}
							>
								<Grid display={'flex'} item xs={6} alignContent='center' alignItems={'center'} justifyContent='right' justifyItems={'right'}>
									<Typography variant="h5" color={'GrayText'}>{onStatusType(el?.statusTypes[0]?.code)}</Typography>
								</Grid>
								<Grid item xs={10} display={'flex'} alignContent='left' alignItems={'left'} justifyContent='left' justifyItems={'left'}>
									<Typography variant="h5" ><Translate>{el?.type?.title}</Translate></Typography>
								</Grid>
							</Grid>
						)
					})}
					<Grid item alignItems={'center'} justifyContent={'center'} container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
						<Grid item xs={4} sm={8} md={4} position={'relative'}>
							<Box
								justifyContent={'center'}
								component="img"
								position={'relative'}
								sx={{
									objectFit: 'cover',
									width: 350,
								}}
								alt="xponend.com zhasapp.com sultandelux"
								src={require('../assets/front.png')}
							/>
							{carsStore?.currentCarInspection?.outers?.map((el, index) => {
								const change = el?.statusTypes[0]?.code
								const backDoorLeft = el?.type.code == 'P033'
								const frontFenderLeft = el?.type.code == 'P021'
								const quarterPanelLeft = el?.type.code == 'P061'
								const hood = el?.type.code == 'P011'
								// const hood = el?.type.code == 'P151'
								// const hood = el?.type.code == 'P161'
								const frontDoorLeft = el?.type.code == 'P031'
								const frontDoorRight = el?.type.code == 'P032'
								const frontFenderRight = el?.type.code == 'P022'
								// const hood = el?.type.code == 'P121'
								// const hood = el?.type.code == 'P122'
								// const hood = el?.type.code == 'P131'
								// const hood = el?.type.code == 'P132'
								// const hood = el?.type.code == 'P011'
								// const hood = el?.type.code == 'P112'
								// const hood = el?.type.code == 'P191'
								// const hood = el?.type.code == 'P141'
								// const hood = el?.type.code == 'P144'
								// const hood = el?.type.code == 'P142'
								// const hood = el?.type.code == 'P145'
								// const hood = el?.type.code == 'P143'
								// const hood = el?.type.code == 'P146'
								const quarterPanelRight = el?.type.code == 'P062'
								const radiatorSupport = el?.type.code == 'P051'
								// const hood = el?.type.code == 'P033'
								const backDoorRight = el?.type.code == 'P034'
								// const hood = el?.type.code == 'P181'
								// const hood = el?.type.code == 'P123'
								// const hood = el?.type.code == 'P124'
								// const hood = el?.type.code == 'P133'
								// const hood = el?.type.code == 'P134'
								// const hood = el?.type.code == 'P071'
								const sidePanelLeft = el?.type.code == 'P081'
								const sidePanelRight = el?.type.code == 'P082'
								// const hood = el?.type.code == 'P171'
								const trunkLead = el?.type.code == 'P041'
								return (
									<Box key={index}>
										{frontFenderLeft && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 1, top: '24%', left: '18%', fontSize: 12
										}}>{change}</Typography>}
										{radiatorSupport && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 1, top: '5%', left: '48%', fontSize: 12
										}}>{change}</Typography>}
										{frontFenderRight && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 1, top: '24%', left: '78%', fontSize: 12
										}}>{change}</Typography>}
										{frontDoorRight && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 1, top: '44%', left: '80%', fontSize: 12
										}}>{change}</Typography>}
										{frontDoorLeft && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 1, top: '44%', left: '18%', fontSize: 12
										}}>{change}</Typography>}
										{backDoorRight && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 1, top: '60%', left: '78%', fontSize: 12
										}}>{change}</Typography>}
										{backDoorLeft && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 1, top: '60%', left: '18%', fontSize: 12
										}}>{change}</Typography>
										}
										{quarterPanelLeft && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 1, top: '80%', left: '18%', fontSize: 12
										}}>{change}</Typography>
										}
										{quarterPanelRight && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 1, top: '80%', left: '78%', fontSize: 12
										}}>{change}</Typography>
										}
										{sidePanelRight && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 1, top: '52%', left: '88%', fontSize: 12
										}}>{change}</Typography>
										}
										{sidePanelLeft && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 1, top: '52%', left: '7%', fontSize: 12
										}}>{change}</Typography>
										}
										{hood && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 1, top: '20%', left: '48%', fontSize: 12
										}}>{change}</Typography>
										}
										{trunkLead && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 1, top: '92%', left: '48%', fontSize: 12
										}}>{change}</Typography>
										}
									</Box>
								)
							})
							}

						</Grid>
						<Grid item xs={4} sm={8} md={4} position={'relative'} >
							<Box
								justifyContent={'center'}
								component="img"
								position={'relative'}
								sx={{
									objectFit: 'cover',
									width: 350,
								}}
								alt="xponend.com zhasapp.com sultandelux"
								src={require('../assets/back.png')}
							/>
							{carsStore?.currentCarInspection?.outers?.map((el, index) => {
								const change = el?.statusTypes[0]?.code
								// const hood = el?.type.code == 'P151'
								// const hood = el?.type.code == 'P161'
								// const hood = el?.type.code == 'P031'
								// const hood = el?.type.code == 'P091'
								const frontSideMemberLeft = el?.type.code == 'P121'
								const frontSideMemberRight = el?.type.code == 'P122'
								const frontWheelhouseLeft = el?.type.code == 'P131'
								const frontWheelhouseRight = el?.type.code == 'P132'
								// const hood = el?.type.code == 'P011'
								const insidePanelLeft = el?.type.code == 'P111'
								const insidePanelRight = el?.type.code == 'P112'
								const frontPanel = el?.type.code == 'P091'
								const packageTray = el?.type.code == 'P191'
								// const hood = el?.type.code == 'P141'
								// const hood = el?.type.code == 'P144'
								// const hood = el?.type.code == 'P142'
								// const hood = el?.type.code == 'P145'
								// const hood = el?.type.code == 'P143'
								const fillerPanelRearRight = el?.type.code == 'P146'
								// const hood = el?.type.code == 'P061'
								// const hood = el?.type.code == 'P062'
								// const hood = el?.type.code == 'P033'
								const backPanel = el?.type.code == 'P181'
								const rearSideMemberLeft = el?.type.code == 'P123'
								const rearSideMemberRight = el?.type.code == 'P124'
								// const hood = el?.type.code == 'P133'
								// const hood = el?.type.code == 'P134'
								// const hood = el?.type.code == 'P071'
								// const hood = el?.type.code == 'P081'
								const trunkFloor = el?.type.code == 'P171'
								return (
									<Box key={index}>
										{insidePanelLeft && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 2, top: '20%', left: '36%', fontSize: 12
										}}>{change}</Typography>}
										{insidePanelRight && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 2, top: '20%', left: '60%', fontSize: 12
										}}>{change}</Typography>}
										{frontPanel && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 2, top: '10%', left: '48%', fontSize: 12
										}}>{change}</Typography>}
										{backPanel && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 2, top: '90%', left: '48%', fontSize: 12
										}}>{change}</Typography>}
										{trunkFloor && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 2, top: '80%', left: '48%', fontSize: 12
										}}>{change}</Typography>}
										{packageTray && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 2, top: '73%', left: '48%', fontSize: 12
										}}>{change}</Typography>}
										{rearSideMemberRight && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 2, top: '82%', left: '55%', fontSize: 12
										}}>{change}</Typography>}
										{rearSideMemberLeft && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 2, top: '82%', left: '40%', fontSize: 12
										}}>{change}</Typography>}
										{frontSideMemberRight && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 2, top: '22%', left: '54%', fontSize: 12
										}}>{change}</Typography>}
										{frontSideMemberLeft && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 2, top: '22%', left: '42%', fontSize: 12
										}}>{change}</Typography>}
										{fillerPanelRearRight && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 2, top: '68%', left: '68%', fontSize: 12
										}}>{change}</Typography>}
										{frontWheelhouseRight && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 2, top: '28%', left: '58%', fontSize: 10
										}}>{change}</Typography>}
										{frontWheelhouseLeft && <Typography variant='subtitle2' sx={{
											borderRadius: 5, backgroundColor: onColorStatus(change),
											color: 'white', width: 18, textAlign: 'center', position: 'absolute',
											zIndex: 2, top: '28%', left: '35%', fontSize: 10
										}}>{change}</Typography>}
									</Box>
								)
							})
							}
						</Grid>

					</Grid>
					<Grid container gap={1} flex={1} mt={2} display='flex' flexDirection={'row'} justifyContent='center' justifyItems={'center'} alignContent={'center'}>
						<Grid item display='flex' justifyContent='center' flexDirection={'row'} xs={5}>
							<Typography variant="h5" sx={{ borderRadius: 5, backgroundColor: onColorStatus('X'), color: 'white', paddingX: 1 }}>X</Typography>
							<Typography variant="h5" ml={0.5}>Замена</Typography>
						</Grid>
						<Grid item display='flex' justifyContent='center' flexDirection={'row'} xs={5}>
							<Typography variant="h5" sx={{ borderRadius: 5, backgroundColor: onColorStatus('W'), color: 'white', paddingX: 1 }}>W</Typography>
							<Typography variant="h5" ml={0.5}>Сварка</Typography>
						</Grid>
						<Grid item display='flex' justifyContent='center' flexDirection={'row'} xs={5}>
							<Typography variant="h5" sx={{ borderRadius: 5, backgroundColor: onColorStatus('C'), color: 'white', paddingX: 1 }}>C</Typography>
							<Typography variant="h5" ml={0.5}>Коррозия</Typography>
						</Grid>
						<Grid item display='flex' justifyContent='center' flexDirection={'row'} xs={5}>
							<Typography variant="h5" sx={{ borderRadius: 5, backgroundColor: onColorStatus('A'), color: 'white', paddingX: 1 }}>A</Typography>
							<Typography variant="h5" ml={0.5}>Царапина</Typography>
						</Grid>
						<Grid item display='flex' justifyContent='center' flexDirection={'row'} xs={5}>
							<Typography variant="h5" sx={{ borderRadius: 5, backgroundColor: onColorStatus('U'), color: 'white', paddingX: 1 }}>U</Typography>
							<Typography variant="h5" ml={0.5}>Нарушение</Typography>
						</Grid>
						<Grid item display='flex' justifyContent='center' flexDirection={'row'} xs={5}>
							<Typography variant="h5" sx={{ borderRadius: 5, backgroundColor: onColorStatus('T'), color: 'white', paddingX: 1 }}>T</Typography>
							<Typography variant="h5" ml={0.5}>Повреждение</Typography>
						</Grid>
					</Grid>
					{/* <Box mt={2} ml={2} >
					<Grid alignItems={'center'} justifyContent={'center'} container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12, ml: 20 }}>
						<Grid item xs={4} sm={8} md={4} >
							<Box
								justifyContent={'center'}
								component="img"
								sx={{
									objectFit: 'cover',
									width: 300,
								}}
								alt={`xponend.com zhasapp.com sultandelux ${carId}`}
								src={`https://ci.encar.com/carsdata/cars/inspection/${carId}_photoFront.jpg`}
							/>
						</Grid>
						<Grid item xs={4} sm={8} md={4} >
							<Box
								justifyContent={'center'}
								component="img"
								sx={{
									objectFit: 'cover',
									width: 300,
								}}
								alt="xponend.com zhasapp.com sultandelux"
								src={`https://ci.encar.com/carsdata/cars/inspection/${carId}_photoBack.jpg`}
							/>
						</Grid>
					</Grid>
				</Box> */}
				</Box>
			</Container >
		)
	}
	else {
		return (
			<Grid container justifyContent={'center'} display='flex'>
				<ProgressBar
					height="80"
					width="80"
					ariaLabel="progress-bar-loading"
					wrapperStyle={{}}
					wrapperClass="progress-bar-wrapper"
					borderColor='black'
					barColor='gold'
				/>
			</Grid>
		)
	}
})

export default Car;
