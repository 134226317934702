import camelcaseKeysDeep from 'camelcase-keys-deep';
import camelcase from 'camelcase';
import I18n from 'i18n-js';

const responseHandler = async <T>(response: Response): Promise<T> => {
	const { status } = response;

	let result = null;
	if (status === 204) {
		return {} as any;
	}
	if (status >= 200 && status < 300) {
		try {
			result = await response.json();
			return camelcaseKeysDeep(result);
		} catch (e) {
			throw new Error(`Incorrect response format for status: ${status}`);
		}
	}
	if (
		status === 400 ||
		status === 403 ||
		status === 404 ||
		status === 409 ||
		status === 500 ||
		status === 422
	) {
		try {
			result = await response.json();
			return camelcaseKeysDeep(result);
		} catch (e) {
			throw new Error(`Incorrect response format for status: ${status}`);
		}
	}
	try {
		result = await response.json();
		result = camelcaseKeysDeep<object>(result);
	} catch (e) {
		throw new Error(`${status}`);
	}
	const { description = null, error = null } = result;
	if (description) {
		let errorMessage = '';
		try {
			const json = JSON.parse(description);
			errorMessage = json[`description_${I18n.locale}`];
		} catch (e) {
			throw new Error(description);
		}
		throw new Error(errorMessage);
	} else if (error) {
		throw new Error(I18n.t(`authError.${camelcase(error)}`));
	} else {
		throw new Error(`Incorrect error format for status: ${status}`);
	}
};

export { responseHandler };
