import React from "react";
import { CssBaseline, Container, Box } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";

export default function Layout({ children }: any) {
  return (
    <Box>
      <CssBaseline />
      <Header />
      <Container
        sx={{
          mx: "auto",
          mt: 10,
        }}
      >
        <Outlet />
      </Container>
      <Footer />
    </Box>
  );
}
