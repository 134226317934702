
export const Manufactures = [
	{
		value: 'Hyundai', key: '(C.CarType.Y._.Manufacturer.%ED%98%84%EB%8C%80.))',
		models: [
			{
				value: 'Granger', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EA%B7%B8%EB%9E%9C%EC%A0%80._.Model.%EB%8D%94+%EB%89%B4+%EA%B7%B8%EB%9E%9C%EC%A0%80+IG.))))',
				subModels: [
					{ value: 'The New Grandeur IG', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EA%B7%B8%EB%9E%9C%EC%A0%80._.Model.%EB%8D%94+%EB%89%B4+%EA%B7%B8%EB%9E%9C%EC%A0%80+IG.))))' },
					{ value: 'The New Grandeur IG Hybrid ', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EA%B7%B8%EB%9E%9C%EC%A0%80._.Model.%EB%8D%94+%EB%89%B4+%EA%B7%B8%EB%9E%9C%EC%A0%80+IG+%ED%95%98%EC%9D%B4%EB%B8%8C%EB%A6%AC%EB%93%9C.))))' },
					{ value: 'Grandeur IG ', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EA%B7%B8%EB%9E%9C%EC%A0%80._.Model.%EA%B7%B8%EB%9E%9C%EC%A0%80+IG.))))' },
				]
			},
			{
				value: 'Nexo', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EB%84%A5%EC%8F%98._.Model.%EB%84%A5%EC%8F%98.))))',
			},
			{
				value: 'Venue', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EB%B2%A0%EB%89%B4._.Model.%EB%B2%A0%EB%89%B4.))))',
			},
			{
				value: 'Veloster', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EB%B2%A8%EB%A1%9C%EC%8A%A4%ED%84%B0._.Model.%EB%B2%A8%EB%A1%9C%EC%8A%A4%ED%84%B0+(JS_).))))',
			},
			{
				value: 'Starex', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%8A%A4%ED%83%80%EB%A0%89%EC%8A%A4._.Model.%EB%8D%94+%EB%89%B4+%EA%B7%B8%EB%9E%9C%EB%93%9C+%EC%8A%A4%ED%83%80%EB%A0%89%EC%8A%A4.))))',
				subModels: [
					{ value: 'The New Grand Starex ', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%8A%A4%ED%83%80%EB%A0%89%EC%8A%A4._.Model.%EB%8D%94+%EB%89%B4+%EA%B7%B8%EB%9E%9C%EB%93%9C+%EC%8A%A4%ED%83%80%EB%A0%89%EC%8A%A4.))))' },
				]
			},
			{
				value: 'Staria', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%8A%A4%ED%83%80%EB%A6%AC%EC%95%84._.Model.%EC%8A%A4%ED%83%80%EB%A6%AC%EC%95%84.))))',
			},
			{
				value: 'Santa Fe', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%8B%BC%ED%83%80%ED%8E%98._.Model.%EB%8D%94+%EB%89%B4+%EC%8B%BC%ED%83%80%ED%8E%98.))))',
				subModels: [
					{ value: 'The New Santa Fe', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%8B%BC%ED%83%80%ED%8E%98._.Model.%EB%8D%94+%EB%89%B4+%EC%8B%BC%ED%83%80%ED%8E%98.))))' },
					{ value: 'Santa Fe TM', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%8B%BC%ED%83%80%ED%8E%98._.Model.%EC%8B%BC%ED%83%80%ED%8E%98+TM.))))' },
				]
			},
			{
				value: 'Sonata', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%8F%98%EB%82%98%ED%83%80._.Model.%EC%8F%98%EB%82%98%ED%83%80+(DN8_).))))',
				subModels: [
					{ value: 'Sonata (DN8)', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%8F%98%EB%82%98%ED%83%80._.Model.%EC%8F%98%EB%82%98%ED%83%80+(DN8_).))))' },
					{ value: 'Sonata Hybrid (DN8)', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%8F%98%EB%82%98%ED%83%80._.Model.%EC%8F%98%EB%82%98%ED%83%80+%ED%95%98%EC%9D%B4%EB%B8%8C%EB%A6%AC%EB%93%9C+(DN8_).))))' },
					{ value: 'Sonata New Rise', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%8F%98%EB%82%98%ED%83%80._.Model.%EC%8F%98%EB%82%98%ED%83%80+%EB%89%B4+%EB%9D%BC%EC%9D%B4%EC%A6%88.))))' },
					{ value: 'Sonata New Rise Hybrid', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%8F%98%EB%82%98%ED%83%80._.Model.%EC%8F%98%EB%82%98%ED%83%80+%EB%89%B4+%EB%9D%BC%EC%9D%B4%EC%A6%88+%ED%95%98%EC%9D%B4%EB%B8%8C%EB%A6%AC%EB%93%9C.))))' },
				]
			},
			{
				value: 'Ioniq 5', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%95%84%EC%9D%B4%EC%98%A4%EB%8B%895._.Model.%EC%95%84%EC%9D%B4%EC%98%A4%EB%8B%895.))))',
			},
			{
				value: 'Ioni1', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%95%84%EC%9D%B4%EC%98%A4%EB%8B%89._.Model.%EB%8D%94+%EB%89%B4+%EC%95%84%EC%9D%B4%EC%98%A4%EB%8B%89+%ED%95%98%EC%9D%B4%EB%B8%8C%EB%A6%AC%EB%93%9C.))))',
				subModels: [
					{ value: 'The New Ioniq Hybrid', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%95%84%EC%9D%B4%EC%98%A4%EB%8B%89._.Model.%EB%8D%94+%EB%89%B4+%EC%95%84%EC%9D%B4%EC%98%A4%EB%8B%89+%ED%95%98%EC%9D%B4%EB%B8%8C%EB%A6%AC%EB%93%9C.))))' },
					{ value: 'The New Ioniq Electric', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%95%84%EC%9D%B4%EC%98%A4%EB%8B%89._.Model.%EB%8D%94+%EB%89%B4+%EC%95%84%EC%9D%B4%EC%98%A4%EB%8B%89+%EC%9D%BC%EB%A0%89%ED%8A%B8%EB%A6%AD.))))' },
				]
			},
			{
				value: 'Casper', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%EC%BA%90%EC%8A%A4%ED%8D%BC._.Model.%EC%BA%90%EC%8A%A4%ED%8D%BC.))))',
			},
			{
				value: 'Tucson', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%ED%88%AC%EC%8B%BC._.Model.%ED%88%AC%EC%8B%BC+(NX4_).))))',
				subModels: [
					{ value: 'Tucson (NX4) ', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%ED%88%AC%EC%8B%BC._.Model.%ED%88%AC%EC%8B%BC+(NX4_).))))' },
					{ value: 'Tucson Hybrid (NX4)', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%ED%88%AC%EC%8B%BC._.Model.%ED%88%AC%EC%8B%BC+%ED%95%98%EC%9D%B4%EB%B8%8C%EB%A6%AC%EB%93%9C+(NX4_).))))' },
					{ value: 'All New Tucson', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%ED%88%AC%EC%8B%BC._.Model.%EC%98%AC+%EB%89%B4+%ED%88%AC%EC%8B%BC.))))' },
				]
			},
			{
				value: 'Palisade', key: '',
				subModels: [
					{ value: 'The New Palisade', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%ED%8C%B0%EB%A6%AC%EC%84%B8%EC%9D%B4%EB%93%9C._.Model.%EB%8D%94+%EB%89%B4+%ED%8C%B0%EB%A6%AC%EC%84%B8%EC%9D%B4%EB%93%9C.))))' },
					{ value: 'Palisade', key: '(C.CarType.Y._.(C.Manufacturer.%ED%98%84%EB%8C%80._.(C.ModelGroup.%ED%8C%B0%EB%A6%AC%EC%84%B8%EC%9D%B4%EB%93%9C._.Model.%ED%8C%B0%EB%A6%AC%EC%84%B8%EC%9D%B4%EB%93%9C.))))' },
				]
			},
		]
	},
	{
		value: 'Genesis', key: '(C.CarType.Y._.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4.))',
		models: [
			{
				value: 'EQ900', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.EQ900._.Model.EQ900.))))'
			},
			{
				value: 'G70', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.G70._.Model.G70.))))',
				subModels: [
					{ value: 'The New G70', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.G70._.Model.%EB%8D%94+%EB%89%B4+G70.))))' },
					{ value: 'The New G70 Shooting Brake', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.G70._.Model.%EB%8D%94+%EB%89%B4+G70+%EC%8A%88%ED%8C%85%EB%B8%8C%EB%A0%88%EC%9D%B4%ED%81%AC.))))' },
					{ value: 'G70 (2017-20 years)', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.G70._.Model.G70.))))' }
				]
			},
			{
				value: 'G80', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.G80._.Model.G80.))))',
				subModels: [
					{ value: ' G80 (RG3) ', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.G80._.Model.G80+(RG3_).))))' },
					{ value: 'Electrified G80 (RG3) ', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.G80._.Model.%EC%9D%BC%EB%A0%89%ED%8A%B8%EB%A6%AC%ED%8C%8C%EC%9D%B4%EB%93%9C+G80+(RG3_).))))' },
					{ value: 'G80 (2016-20 years)', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.G80._.Model.G80.))))' },
				]
			},
			{
				value: 'G90', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.G90._.Model.G90.))))',
				subModels: [
					{ value: 'G90 (RS4)', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.G90._.Model.G90+(RS4_).))))' },
					{ value: 'G90 (18-21 years)', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.G90._.Model.G90.))))' }
				]
			},
			{ value: 'GV60', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.GV60._.Model.GV60.))))' },
			{
				value: 'GV70', key: '',
				subModels: [
					{ value: 'GV70 (2021)', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.GV70._.Model.GV70.))))' },
					{ value: 'Electrified GV70', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.GV70._.Model.%EC%9D%BC%EB%A0%89%ED%8A%B8%EB%A6%AC%ED%8C%8C%EC%9D%B4%EB%93%9C+GV70.))))' }
				]
			},
			{ value: 'GV80', key: '(C.CarType.Y._.(C.Manufacturer.%EC%A0%9C%EB%84%A4%EC%8B%9C%EC%8A%A4._.(C.ModelGroup.GV80._.Model.GV80.))))' },
		]
	},
	{
		value: 'Kia', key: '(C.CarType.Y._.Manufacturer.%EA%B8%B0%EC%95%84.))',
		models: [
			{
				value: 'K3', key: '',
				subModels: [
					{ value: 'The New K3 2nd Generation', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.K3._.Model.%EB%8D%94+%EB%89%B4+K3+2%EC%84%B8%EB%8C%80.))))' },
					{ value: 'All-New K3 ', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.K3._.Model.%EC%98%AC+%EB%89%B4+K3.))))' },
				]
			},
			{
				value: 'K5', key: '',
				subModels: [
					{ value: 'K5 3rd generation ', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.K5._.Model.K5+3%EC%84%B8%EB%8C%80.))))' },
					{ value: 'K5 Hybrid 3rd Generation ', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.K5._.Model.K5+%ED%95%98%EC%9D%B4%EB%B8%8C%EB%A6%AC%EB%93%9C+3%EC%84%B8%EB%8C%80.))))' },
					{ value: 'The New K5 2nd Generation', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.K5._.Model.%EB%8D%94+%EB%89%B4+K5+2%EC%84%B8%EB%8C%80.))))' },
					{ value: 'The New K5 Hybrid 2nd Generation ', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.K5._.Model.%EB%8D%94+%EB%89%B4+K5+%ED%95%98%EC%9D%B4%EB%B8%8C%EB%A6%AC%EB%93%9C+2%EC%84%B8%EB%8C%80.))))' },
				]
			},
			{
				value: 'K7', key: '',
				subModels: [
					{ value: 'K7 Premier', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.K7._.Model.K7+%ED%94%84%EB%A6%AC%EB%AF%B8%EC%96%B4.))))' },
					{ value: 'K7 Premier Hybrid', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.K7._.Model.K7+%ED%94%84%EB%A6%AC%EB%AF%B8%EC%96%B4+%ED%95%98%EC%9D%B4%EB%B8%8C%EB%A6%AC%EB%93%9C.))))' },
					{ value: 'All-New K7', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.K7._.Model.%EC%98%AC+%EB%89%B4+K7.))))' },
					{ value: 'All New K7 Hybrid', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.K7._.Model.%EC%98%AC+%EB%89%B4+K7+%ED%95%98%EC%9D%B4%EB%B8%8C%EB%A6%AC%EB%93%9C.))))' },
				]
			},
			{
				value: 'K8', key: '',
				subModels: [
					{ value: 'K8', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.K8._.Model.K8.))))' },
					{ value: 'K8 Hybrid', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.K8._.Model.K8+%ED%95%98%EC%9D%B4%EB%B8%8C%EB%A6%AC%EB%93%9C.))))' },
				]
			},
			{
				value: 'K9', key: '',
				subModels: [
					{ value: 'The New K9 2nd Generation', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.K9._.Model.%EB%8D%94+%EB%89%B4+K9+2%EC%84%B8%EB%8C%80.))))' },
					{ value: 'The K9 ', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.K9._.Model.%EB%8D%94+K9.))))' },
				]
			},
			{ value: 'EV6', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.EV6._.Model.EV6.))))' },
			{ value: 'EV9', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.EV9._.Model.EV9.))))' },
			{ value: 'X-TREK', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.X-TREK._.Model.X-TREK.))))' },
			{
				value: 'Niro', key: '',
				subModels: [
					{ value: 'The All New Niro', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EB%8B%88%EB%A1%9C._.Model.%EB%94%94+%EC%98%AC+%EB%89%B4+%EB%8B%88%EB%A1%9C.))))' },
					{ value: 'The All-New Niro EV', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EB%8B%88%EB%A1%9C._.Model.%EB%94%94+%EC%98%AC+%EB%89%B4+%EB%8B%88%EB%A1%9C+EV.))))' },
					{ value: 'Niro Plus', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EB%8B%88%EB%A1%9C._.Model.%EB%8B%88%EB%A1%9C+%ED%94%8C%EB%9F%AC%EC%8A%A4.))))' },
					{ value: 'The New Niro', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EB%8B%88%EB%A1%9C._.Model.%EB%8D%94+%EB%89%B4+%EB%8B%88%EB%A1%9C.))))' },
					{ value: 'Niro EV', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EB%8B%88%EB%A1%9C._.Model.%EB%8B%88%EB%A1%9C+EV.))))' },
				]
			},
			{ value: 'Delta', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EB%8D%B8%ED%83%80._.Model.%EB%8D%B8%ED%83%80.))))' },
			{
				value: 'Ray', key: '',
				subModels: [
					{ value: 'The New Kia Ray ', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EB%A0%88%EC%9D%B4._.Model.%EB%8D%94+%EB%89%B4+%EA%B8%B0%EC%95%84+%EB%A0%88%EC%9D%B4.))))' },
					{ value: 'The New Ray', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EB%A0%88%EC%9D%B4._.Model.%EB%8D%94+%EB%89%B4+%EB%A0%88%EC%9D%B4.))))' },
				]
			},
			{
				value: 'Letona', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EB%A0%88%ED%86%A0%EB%82%98._.Model.%EB%A0%88%ED%86%A0%EB%82%98.))))',
			},
			{
				value: 'Morning', key: '',
				subModels: [
					{ value: 'Morning Urban', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EB%AA%A8%EB%8B%9D._.Model.%EB%AA%A8%EB%8B%9D+%EC%96%B4%EB%B0%98.))))' },
					{ value: 'All New Morning (JA) ', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EB%AA%A8%EB%8B%9D._.Model.%EC%98%AC+%EB%89%B4+%EB%AA%A8%EB%8B%9D+(JA_).))))' },
				]
			},
			{
				value: 'Mojave', key: '',
				subModels: [
					{ value: 'Mohave The Master', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EB%AA%A8%ED%95%98%EB%B9%84._.Model.%EB%AA%A8%ED%95%98%EB%B9%84+%EB%8D%94+%EB%A7%88%EC%8A%A4%ED%84%B0.))))' },
					{ value: 'The New Mohave', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EB%AA%A8%ED%95%98%EB%B9%84._.Model.%EB%8D%94+%EB%89%B4+%EB%AA%A8%ED%95%98%EB%B9%84.))))' },
				]
			},
			{
				value: 'Seltos', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%85%80%ED%86%A0%EC%8A%A4._.Model.%EC%85%80%ED%86%A0%EC%8A%A4.))))',
				subModels: [
					{ value: 'The New Seltos ', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%85%80%ED%86%A0%EC%8A%A4._.Model.%EB%8D%94+%EB%89%B4+%EC%85%80%ED%86%A0%EC%8A%A4.))))' },
					{ value: 'Seltos', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%85%80%ED%86%A0%EC%8A%A4._.Model.%EC%85%80%ED%86%A0%EC%8A%A4.))))' },
				]
			},
			{
				value: 'Stonic', key: 'C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%8A%A4%ED%86%A0%EB%8B%89._.Model.%EC%8A%A4%ED%86%A0%EB%8B%89.))))',
			},
			{
				value: 'Stinger', key: '',
				subModels: [
					{ value: 'Stinger Meister', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%8A%A4%ED%8C%85%EC%96%B4._.Model.%EC%8A%A4%ED%8C%85%EC%96%B4+%EB%A7%88%EC%9D%B4%EC%8A%A4%ED%84%B0.))))' },
					{ value: 'Stinger', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%8A%A4%ED%8C%85%EC%96%B4._.Model.%EC%8A%A4%ED%8C%85%EC%96%B4.))))' },
				]
			},
			{
				value: 'Sportage', key: '',
				subModels: [
					{ value: 'Sportage 5th generation', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%8A%A4%ED%8F%AC%ED%8B%B0%EC%A7%80._.Model.%EC%8A%A4%ED%8F%AC%ED%8B%B0%EC%A7%80+5%EC%84%B8%EB%8C%80.))))' },
					{ value: 'Sportage 5th generation hybrid', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%8A%A4%ED%8F%AC%ED%8B%B0%EC%A7%80._.Model.%EC%8A%A4%ED%8F%AC%ED%8B%B0%EC%A7%80+5%EC%84%B8%EB%8C%80+%ED%95%98%EC%9D%B4%EB%B8%8C%EB%A6%AC%EB%93%9C.))))' },
					{ value: 'Sportage The Bold', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%8A%A4%ED%8F%AC%ED%8B%B0%EC%A7%80._.Model.%EC%8A%A4%ED%8F%AC%ED%8B%B0%EC%A7%80+%EB%8D%94+%EB%B3%BC%EB%93%9C.))))' },
				]
			},
			{
				value: 'Sorrento', key: '',
				subModels: [
					{ value: 'Sorento 4th generation ', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%8F%98%EB%A0%8C%ED%86%A0._.Model.%EC%8F%98%EB%A0%8C%ED%86%A0+4%EC%84%B8%EB%8C%80.))))' },
					{ value: 'The New Sorento', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%8F%98%EB%A0%8C%ED%86%A0._.Model.%EB%8D%94+%EB%89%B4+%EC%8F%98%EB%A0%8C%ED%86%A0.))))' },
				]
			},
			{
				value: 'Soul', key: '',
				subModels: [
					{ value: 'Soul Booster ', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%8F%98%EC%9A%B8._.Model.%EC%8F%98%EC%9A%B8+%EB%B6%80%EC%8A%A4%ED%84%B0.))))' },
					{ value: 'Soul Booster EV', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%8F%98%EC%9A%B8._.Model.%EC%8F%98%EC%9A%B8+%EB%B6%80%EC%8A%A4%ED%84%B0+EV.))))' },
					{ value: 'The New Soul ', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%8F%98%EC%9A%B8._.Model.%EB%8D%94+%EB%89%B4+%EC%8F%98%EC%9A%B8.))))' },
				]
			},
			{
				value: 'Carnival', key: '',
				subModels: [
					{ value: 'Carnival 4th generation ', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%B9%B4%EB%8B%88%EB%B0%9C._.Model.%EC%B9%B4%EB%8B%88%EB%B0%9C+4%EC%84%B8%EB%8C%80.)))' },
					{ value: 'The New Carnival', key: '(C.CarType.Y._.(C.Manufacturer.%EA%B8%B0%EC%95%84._.(C.ModelGroup.%EC%B9%B4%EB%8B%88%EB%B0%9C._.Model.%EB%8D%94+%EB%89%B4+%EC%B9%B4%EB%8B%88%EB%B0%9C.))))' },
				]
			},
		]
	},
	// {
	// 	value: 'Chevrolet (GM Daewoo)', key: '(C.CarType.Y._.Manufacturer.%EC%89%90%EB%B3%B4%EB%A0%88(GM%EB%8C%80%EC%9A%B0_).))',
	// 	models: [
	// 		{
	// 			value: 'Malibu', key: '',
	// 			subModels: [
	// 				{ value: 'The New Malibu ', key: '(C.CarType.Y._.(C.Manufacturer.%EC%89%90%EB%B3%B4%EB%A0%88(GM%EB%8C%80%EC%9A%B0_)._.(C.ModelGroup.%EB%A7%90%EB%A6%AC%EB%B6%80._.Model.%EB%8D%94+%EB%89%B4+%EB%A7%90%EB%A6%AC%EB%B6%80.))))' },
	// 			]
	// 		},
	// 		{
	// 			value: 'Spark', key: '',
	// 			subModels: [
	// 				{ value: 'The New Spark', key: '(C.CarType.Y._.(C.Manufacturer.%EC%89%90%EB%B3%B4%EB%A0%88(GM%EB%8C%80%EC%9A%B0_)._.(C.ModelGroup.%EC%8A%A4%ED%8C%8C%ED%81%AC._.Model.%EB%8D%94+%EB%89%B4+%EC%8A%A4%ED%8C%8C%ED%81%AC.))))' },
	// 			]
	// 		},
	// 		{
	// 			value: 'Trax', key: '',
	// 			subModels: [
	// 				{ value: 'The New Trax', key: '(C.CarType.Y._.(C.Manufacturer.%EC%89%90%EB%B3%B4%EB%A0%88(GM%EB%8C%80%EC%9A%B0_)._.(C.ModelGroup.%ED%8A%B8%EB%9E%99%EC%8A%A4._.Model.%EB%8D%94+%EB%89%B4+%ED%8A%B8%EB%9E%99%EC%8A%A4.))))' },
	// 			]
	// 		},
	// 	]
	// },
	{
		value: 'Ssangyong', key: '(C.CarType.Y._.Manufacturer.%EC%8C%8D%EC%9A%A9.))',
		models: [
			{ value: 'New Family', key: '' },
			{
				value: 'Rexton', key: '',
				subModels: [
					{ value: 'he New Rexton Sports', key: '(C.CarType.Y._.(C.Manufacturer.%EC%8C%8D%EC%9A%A9._.(C.ModelGroup.%EB%A0%89%EC%8A%A4%ED%84%B4._.Model.%EB%8D%94+%EB%89%B4+%EB%A0%89%EC%8A%A4%ED%84%B4+%EC%8A%A4%ED%8F%AC%EC%B8%A0.))))' },
					{ value: 'The New Rexton Sports Khan', key: '(C.CarType.Y._.(C.Manufacturer.%EC%8C%8D%EC%9A%A9._.(C.ModelGroup.%EB%A0%89%EC%8A%A4%ED%84%B4._.Model.%EB%8D%94+%EB%89%B4+%EB%A0%89%EC%8A%A4%ED%84%B4+%EC%8A%A4%ED%8F%AC%EC%B8%A0+%EC%B9%B8.))))' },
					{ value: 'Rexton Sports', key: '(C.CarType.Y._.(C.Manufacturer.%EC%8C%8D%EC%9A%A9._.(C.ModelGroup.%EB%A0%89%EC%8A%A4%ED%84%B4._.Model.%EB%A0%89%EC%8A%A4%ED%84%B4+%EC%8A%A4%ED%8F%AC%EC%B8%A0.))))' },
					{ value: 'Rexton Sports Khan', key: '(C.CarType.Y._.(C.Manufacturer.%EC%8C%8D%EC%9A%A9._.(C.ModelGroup.%EB%A0%89%EC%8A%A4%ED%84%B4._.Model.%EB%A0%89%EC%8A%A4%ED%84%B4+%EC%8A%A4%ED%8F%AC%EC%B8%A0+%EC%B9%B8.))))' },
					{ value: 'All New Rexton ', key: '(C.CarType.Y._.(C.Manufacturer.%EC%8C%8D%EC%9A%A9._.(C.ModelGroup.%EB%A0%89%EC%8A%A4%ED%84%B4._.Model.%EC%98%AC+%EB%89%B4+%EB%A0%89%EC%8A%A4%ED%84%B4.))))' },
					{ value: 'G4 Rexton', key: '(C.CarType.Y._.(C.Manufacturer.%EC%8C%8D%EC%9A%A9._.(C.ModelGroup.%EB%A0%89%EC%8A%A4%ED%84%B4._.Model.G4+%EB%A0%89%EC%8A%A4%ED%84%B4.))))' },
				]
			},
			{
				value: 'Korando', key: '',
				subModels: [
					{ value: 'Beautiful Korando', key: '(C.CarType.Y._.(C.Manufacturer.%EC%8C%8D%EC%9A%A9._.(C.ModelGroup.%EC%BD%94%EB%9E%80%EB%8F%84._.Model.%EB%B7%B0%ED%8B%B0%ED%92%80+%EC%BD%94%EB%9E%80%EB%8F%84.))))' },
					{ value: 'New Style Korando', key: '(C.CarType.Y._.(C.Manufacturer.%EC%8C%8D%EC%9A%A9._.(C.ModelGroup.%EC%BD%94%EB%9E%80%EB%8F%84._.Model.%EB%89%B4+%EC%8A%A4%ED%83%80%EC%9D%BC+%EC%BD%94%EB%9E%80%EB%8F%84+C.))))' },
					{ value: 'Korando Turismo', key: '(C.CarType.Y._.(C.Manufacturer.%EC%8C%8D%EC%9A%A9._.(C.ModelGroup.%EC%BD%94%EB%9E%80%EB%8F%84._.Model.%EC%BD%94%EB%9E%80%EB%8F%84+%ED%88%AC%EB%A6%AC%EC%8A%A4%EB%AA%A8.))))' }
				]
			},

			{
				value: 'Tivoli', key: '',
				subModels: [
					{ value: 'Very New Tivoli', key: '(C.CarType.Y._.(C.Manufacturer.%EC%8C%8D%EC%9A%A9._.(C.ModelGroup.%ED%8B%B0%EB%B3%BC%EB%A6%AC._.Model.%EB%B2%A0%EB%A6%AC+%EB%89%B4+%ED%8B%B0%EB%B3%BC%EB%A6%AC.))))' },
					{
						value: 'Tivoli Armor ', key: '(C.CarType.Y._.(C.Manufacturer.%EC%8C%8D%EC%9A%A9._.(C.ModelGroup.%ED%8B%B0%EB%B3%BC%EB%A6%AC._.Model.%ED%8B%B0%EB%B3%BC%EB%A6%AC+%EC%95%84%EB%A8%B8.))))'
					}
				]
			},
		]
	},
	{
		value: 'BMW', key: '(C.CarType.N._.Manufacturer.BMW.))',
		models: [
			{ value: 'M2 (F87)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.M2._.Model.M2+(F87_).))))' },
			{ value: '5 Series(G30)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.5%EC%8B%9C%EB%A6%AC%EC%A6%88._.Model.5%EC%8B%9C%EB%A6%AC%EC%A6%88+(G30_).))))' },
			{ value: '3 Series (G20)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.3%EC%8B%9C%EB%A6%AC%EC%A6%88._.Model.3%EC%8B%9C%EB%A6%AC%EC%A6%88+(G20_).))))' },
			{ value: '7 Series (G11)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.7%EC%8B%9C%EB%A6%AC%EC%A6%88._.Model.7%EC%8B%9C%EB%A6%AC%EC%A6%88+(G11_).))))' },
			{ value: '6 Series GT (G32)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.%EA%B7%B8%EB%9E%80%ED%88%AC%EB%A6%AC%EC%8A%A4%EB%AA%A8+(GT_)._.Model.6%EC%8B%9C%EB%A6%AC%EC%A6%88+GT+(G32_).))))' },
			{ value: '1 Series (F40)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.1%EC%8B%9C%EB%A6%AC%EC%A6%88._.Model.1%EC%8B%9C%EB%A6%AC%EC%A6%88+(F40_).))))' },
			{ value: '8 Series (G15)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.8%EC%8B%9C%EB%A6%AC%EC%A6%88._.Model.8%EC%8B%9C%EB%A6%AC%EC%A6%88+(G15_).))))' },
			{ value: 'M5 (F90)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.M5._.Model.M5+(F90_).))))' },
			{ value: 'M8 (G15)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.M8._.Model.M8+(G15_).))))' },
			{ value: 'X1 (F48)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.X1._.Model.X1+(F48_).))))' },
			{ value: 'X2 (F39)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.X2._.Model.X2+(F39_).))))' },
			{ value: 'X3 (G01)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.X3._.Model.X3+(G01_).))))' },
			{ value: 'X4 (G02)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.X4._.Model.X4+(G02_).))))' },
			{ value: 'X5 (G05)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.X5._.Model.X5+(G05_).))))' },
			{ value: 'X6 (G06)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.X6._.Model.X6+(G06_).))))' },
			{ value: 'X7 (G07)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.X7._.Model.X7+(G07_).))))' },
			{ value: 'X3M (G01)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.X3M._.Model.X3M+(G01_).))))' },
			{ value: 'X4M (G02)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.X4M._.Model.X4M+(G02_).))))' },
			{ value: 'X5M (G05)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.X5M._.Model.X5M+(G05_).))))' },
			{ value: 'X6M (G06)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.X6M._.Model.X6M+(G06_).))))' },
			{ value: 'Z4 (G29)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.Z4._.Model.Z4+(G29_).))))' },
			{ value: 'BMW i3', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.i3._.Model.i3.))))' },
			{ value: 'BMW i8', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.i8._.Model.i8.))))' },
			{ value: 'BMW iX3', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.iX3._.Model.iX3.))))' },
			{ value: 'BMW iX', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.iX._.Model.iX.))))' },
			{
				value: '4 Series', key: '',
				subModels: [
					{ value: '4 Series (G22)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.4%EC%8B%9C%EB%A6%AC%EC%A6%88._.Model.4%EC%8B%9C%EB%A6%AC%EC%A6%88+(G22_).))))' },
					{ value: '4 Series (F32)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.4%EC%8B%9C%EB%A6%AC%EC%A6%88._.Model.4%EC%8B%9C%EB%A6%AC%EC%A6%88+(F32_).))))' },
				],
			},
			{
				value: 'M3', key: '',
				subModels: [
					{ value: 'М3 (F80)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.M3._.Model.M3+(F80_).))))' },
					{ value: 'M3 (G80)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.M3._.Model.M3+(G80_).))))' },
				],
			},
			{
				value: 'M4', key: '',
				subModels: [
					{ value: 'M4 (G82)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.M4._.Model.M4+(G82_).))))' },
					{ value: 'M4 (F82)', key: '(C.CarType.N._.(C.Manufacturer.BMW._.(C.ModelGroup.M4._.Model.M4+(F82_).))))' },
				],
			}
		]
	},
	// {
	// 	value: 'Nissan', key: '(C.CarType.N._.Manufacturer.%EB%8B%9B%EC%82%B0.))',
	// 	models: [
	// 		{ value: 'Altima (L34)', key: '(C.CarType.N._.(C.Manufacturer.%EB%8B%9B%EC%82%B0._.(C.ModelGroup.%EC%95%8C%ED%8B%B0%EB%A7%88._.Model.%EC%95%8C%ED%8B%B0%EB%A7%88+(L34_).))))' },
	// 		{ value: 'Cube', key: '(C.CarType.N._.(C.Manufacturer.%EB%8B%9B%EC%82%B0._.(C.ModelGroup.%ED%81%90%EB%B8%8C._.Model.%ED%81%90%EB%B8%8C.))))' },
	// 		{ value: 'Juke', key: '(C.CarType.N._.(C.Manufacturer.%EB%8B%9B%EC%82%B0._.(C.ModelGroup.%EC%A5%AC%ED%81%AC._.Model.%EC%A5%AC%ED%81%AC.))))' },
	// 		{ value: 'Qashqai 2nd generation ', key: '(C.CarType.N._.(C.Manufacturer.%EB%8B%9B%EC%82%B0._.(C.ModelGroup.%EC%BA%90%EC%8B%9C%EC%B9%B4%EC%9D%B4._.Model.%EC%BA%90%EC%8B%9C%EC%B9%B4%EC%9D%B4+2%EC%84%B8%EB%8C%80.))))' },
	// 		{ value: 'Maxima (A36) ', key: '(C.CarType.N._.(C.Manufacturer.%EB%8B%9B%EC%82%B0._.(C.ModelGroup.%EB%A7%A5%EC%8B%9C%EB%A7%88._.Model.%EB%A7%A5%EC%8B%9C%EB%A7%88+(A36_).))))' },
	// 		{ value: '370Z', key: '(C.CarType.N._.(C.Manufacturer.%EB%8B%9B%EC%82%B0._.(C.ModelGroup.370Z._.Model.370Z.))))' },
	// 	]
	// },
	// {
	// 	value: 'Daihatsu', key: '(C.CarType.N._.Manufacturer.%EB%8B%A4%EC%9D%B4%ED%95%98%EC%AF%94.))',
	// 	models: [
	// 		{ value: 'Copen', key: '(C.CarType.N._.(C.Manufacturer.%EB%8B%A4%EC%9D%B4%ED%95%98%EC%AF%94._.(C.ModelGroup.%EC%BD%94%ED%8E%9C._.Model.%EC%BD%94%ED%8E%9C.))))' },
	// 	]
	// },
	// {
	// 	value: 'Dodge', key: '(C.CarType.N._.Manufacturer.%EB%8B%B7%EC%A7%80.))',
	// 	models: [
	// 		{ value: 'Ram Pickup', key: '(C.Manufacturer.%EB%8B%B7%EC%A7%80._.(C.ModelGroup.%EB%9E%A8%ED%94%BD%EC%97%85._.Model.%EB%9E%A8%ED%94%BD%EC%97%85.)))' },
	// 	]
	// },
	{
		value: 'Toyota', key: '(C.CarType.N._.Manufacturer.%EB%8F%84%EC%9A%94%ED%83%80.))',
		models: [
			{ value: 'Camry (XV70)', key: '(C.CarType.N._.(C.Manufacturer.%EB%8F%84%EC%9A%94%ED%83%80._.(C.ModelGroup.%EC%BA%A0%EB%A6%AC._.Model.%EC%BA%A0%EB%A6%AC+(XV70_).))))' },
			{ value: 'Prius 4th generation', key: '(C.CarType.N._.(C.Manufacturer.%EB%8F%84%EC%9A%94%ED%83%80._.(C.ModelGroup.%ED%94%84%EB%A6%AC%EC%9A%B0%EC%8A%A4._.Model.%ED%94%84%EB%A6%AC%EC%9A%B0%EC%8A%A4+4%EC%84%B8%EB%8C%80.))))' },
			{ value: 'Prius Prime ', key: '(C.CarType.N._.(C.Manufacturer.%EB%8F%84%EC%9A%94%ED%83%80._.(C.ModelGroup.%ED%94%84%EB%A6%AC%EC%9A%B0%EC%8A%A4._.Model.%ED%94%84%EB%A6%AC%EC%9A%B0%EC%8A%A4+%ED%94%84%EB%9D%BC%EC%9E%84.))))' },
			{ value: 'Prius C', key: '(C.CarType.N._.(C.Manufacturer.%EB%8F%84%EC%9A%94%ED%83%80._.(C.ModelGroup.%ED%94%84%EB%A6%AC%EC%9A%B0%EC%8A%A4._.Model.%ED%94%84%EB%A6%AC%EC%9A%B0%EC%8A%A4+C.))))' },
			{ value: 'Sienna 4th generation', key: '(C.CarType.N._.(C.Manufacturer.%EB%8F%84%EC%9A%94%ED%83%80._.(C.ModelGroup.%EC%8B%9C%EC%97%90%EB%82%98._.Model.%EC%8B%9C%EC%97%90%EB%82%98+4%EC%84%B8%EB%8C%80.))))' },
			{ value: 'RAV4 5th generation', key: '(C.CarType.N._.(C.Manufacturer.%EB%8F%84%EC%9A%94%ED%83%80._.(C.ModelGroup.RAV4._.Model.RAV4+5%EC%84%B8%EB%8C%80.))))' },
		]
	},
	{
		value: 'Lamborghini', key: '(C.CarType.N._.Manufacturer.%EB%9E%8C%EB%B3%B4%EB%A5%B4%EA%B8%B0%EB%8B%88.))',
		models: [
			{ value: 'Urus', key: '(C.CarType.N._.(C.Manufacturer.%EB%9E%8C%EB%B3%B4%EB%A5%B4%EA%B8%B0%EB%8B%88._.(C.ModelGroup.%EC%9A%B0%EB%A3%A8%EC%8A%A4._.Model.%EC%9A%B0%EB%A3%A8%EC%8A%A4.))))', },
			{ value: 'Huracan', key: '(C.CarType.N._.(C.Manufacturer.%EB%9E%8C%EB%B3%B4%EB%A5%B4%EA%B8%B0%EB%8B%88._.(C.ModelGroup.%EC%9A%B0%EB%9D%BC%EC%B9%B8._.Model.%EC%9A%B0%EB%9D%BC%EC%B9%B8.))))', }
		]
	},
	{
		value: 'Land Rover', key: '(C.CarType.N._.Manufacturer.%EB%9E%9C%EB%93%9C%EB%A1%9C%EB%B2%84.))',
		models: [
			{ value: 'Discovery Sport 2nd generation', key: '(C.CarType.N._.(C.Manufacturer.%EB%9E%9C%EB%93%9C%EB%A1%9C%EB%B2%84._.(C.ModelGroup.%EB%94%94%EC%8A%A4%EC%BB%A4%EB%B2%84%EB%A6%AC+%EC%8A%A4%ED%8F%AC%EC%B8%A0._.Model.%EB%94%94%EC%8A%A4%EC%BB%A4%EB%B2%84%EB%A6%AC+%EC%8A%A4%ED%8F%AC%EC%B8%A0+2%EC%84%B8%EB%8C%80.))))' },
			{ value: 'Discovery 5', key: '(C.CarType.N._.(C.Manufacturer.%EB%9E%9C%EB%93%9C%EB%A1%9C%EB%B2%84._.(C.ModelGroup.%EB%94%94%EC%8A%A4%EC%BB%A4%EB%B2%84%EB%A6%AC._.Model.%EB%94%94%EC%8A%A4%EC%BB%A4%EB%B2%84%EB%A6%AC+5.))))' },
			{ value: 'Range Rover Velar', key: '(C.CarType.N._.(C.Manufacturer.%EB%9E%9C%EB%93%9C%EB%A1%9C%EB%B2%84._.(C.ModelGroup.%EB%A0%88%EC%9D%B8%EC%A7%80%EB%A1%9C%EB%B2%84+%EB%B2%A8%EB%9D%BC._.Model.%EB%A0%88%EC%9D%B8%EC%A7%80%EB%A1%9C%EB%B2%84+%EB%B2%A8%EB%9D%BC.))))' },
			{ value: 'Range Rover Sport 2nd Generation', key: '(C.CarType.N._.(C.Manufacturer.%EB%9E%9C%EB%93%9C%EB%A1%9C%EB%B2%84._.(C.ModelGroup.%EB%A0%88%EC%9D%B8%EC%A7%80%EB%A1%9C%EB%B2%84+%EC%8A%A4%ED%8F%AC%EC%B8%A0._.Model.%EB%A0%88%EC%9D%B8%EC%A7%80%EB%A1%9C%EB%B2%84+%EC%8A%A4%ED%8F%AC%EC%B8%A0+2%EC%84%B8%EB%8C%80.))))' },
			{ value: 'Range Rover Evoque 2nd Generation', key: '(C.CarType.N._.(C.Manufacturer.%EB%9E%9C%EB%93%9C%EB%A1%9C%EB%B2%84._.(C.ModelGroup.%EB%A0%88%EC%9D%B8%EC%A7%80%EB%A1%9C%EB%B2%84+%EC%9D%B4%EB%B3%B4%ED%81%AC._.Model.%EB%A0%88%EC%9D%B8%EC%A7%80%EB%A1%9C%EB%B2%84+%EC%9D%B4%EB%B3%B4%ED%81%AC+2%EC%84%B8%EB%8C%80.))))' },
			{
				value: 'Range Rover', key: '',
				subModels: [
					{ value: 'Range Rover 5th generation', key: '(C.CarType.N._.(C.Manufacturer.%EB%9E%9C%EB%93%9C%EB%A1%9C%EB%B2%84._.(C.ModelGroup.%EB%A0%88%EC%9D%B8%EC%A7%80%EB%A1%9C%EB%B2%84._.Model.%EB%A0%88%EC%9D%B8%EC%A7%80%EB%A1%9C%EB%B2%84+5%EC%84%B8%EB%8C%80.))))' },
					{ value: 'Range Rover 4th generation', key: '(C.CarType.N._.(C.Manufacturer.%EB%9E%9C%EB%93%9C%EB%A1%9C%EB%B2%84._.(C.ModelGroup.%EB%A0%88%EC%9D%B8%EC%A7%80%EB%A1%9C%EB%B2%84._.Model.%EB%A0%88%EC%9D%B8%EC%A7%80%EB%A1%9C%EB%B2%84+4%EC%84%B8%EB%8C%80.))))' },
				]
			},
		]
	},
	{
		value: 'Lexus', key: '(C.CarType.N._.Manufacturer.%EB%A0%89%EC%84%9C%EC%8A%A4.))',
		models: [
			{
				value: 'ES', key: '',
				subModels: [
					{ value: 'ES300h 7th generation', key: '(C.CarType.N._.(C.Manufacturer.%EB%A0%89%EC%84%9C%EC%8A%A4._.(C.ModelGroup.ES._.Model.ES300h+7%EC%84%B8%EB%8C%80.))))' },
				]
			},
			{
				value: 'LS', key: '',
				subModels: [
					{ value: 'LS500 5th generation', key: '(C.CarType.N._.(C.Manufacturer.%EB%A0%89%EC%84%9C%EC%8A%A4._.(C.ModelGroup.LS._.Model.LS500+5%EC%84%B8%EB%8C%80.))))' },
				]
			},
			{
				value: 'UX', key: '',
				subModels: [
					{ value: 'UX250h', key: '(C.CarType.N._.(C.Manufacturer.%EB%A0%89%EC%84%9C%EC%8A%A4._.(C.ModelGroup.LS._.Model.LS500+5%EC%84%B8%EB%8C%80.))))' },
				]
			},
		]
	},
	{
		value: 'Rolls Royce', key: '(C.CarType.N._.Manufacturer.%EB%A1%A4%EC%8A%A4%EB%A1%9C%EC%9D%B4%EC%8A%A4.))',
		models: [
			{
				value: 'Ghost', key: '',
				subModels: [
					{ value: 'Ghost 2nd Generation ', key: '(C.CarType.N._.(C.Manufacturer.%EB%A1%A4%EC%8A%A4%EB%A1%9C%EC%9D%B4%EC%8A%A4._.(C.ModelGroup.%EA%B3%A0%EC%8A%A4%ED%8A%B8._.Model.%EA%B3%A0%EC%8A%A4%ED%8A%B8+2%EC%84%B8%EB%8C%80.))))' },
				]
			},
			{
				value: 'Race', key: '(C.CarType.N._.(C.Manufacturer.%EB%A1%A4%EC%8A%A4%EB%A1%9C%EC%9D%B4%EC%8A%A4._.(C.ModelGroup.%EB%A0%88%EC%9D%B4%EC%8A%A4._.Model.%EB%A0%88%EC%9D%B4%EC%8A%A4.))))',
			},
			{
				value: 'Phantom', key: '(C.CarType.N._.(C.Manufacturer.%EB%A1%A4%EC%8A%A4%EB%A1%9C%EC%9D%B4%EC%8A%A4._.(C.ModelGroup.%ED%8C%AC%ED%85%80._.Model.%ED%8C%AC%ED%85%80.))))',
			},
		]
	},
	{
		value: 'Lincoln', key: '(C.CarType.N._.Manufacturer.%EB%A7%81%EC%BB%A8.))',
		models: [
			{
				value: 'MKZ', key: '',
				subModels: [
					{ value: 'New MKZ', key: '(C.CarType.N._.(C.Manufacturer.%EB%A7%81%EC%BB%A8._.(C.ModelGroup.MKZ._.Model.%EB%89%B4+MKZ.))))' },
				]
			},
			{
				value: 'Continental', key: '',
				subModels: [
					{ value: 'Continental 10th generation ', key: '(C.CarType.N._.(C.Manufacturer.%EB%A7%81%EC%BB%A8._.(C.ModelGroup.%EC%BB%A8%ED%8B%B0%EB%84%A8%ED%83%88._.Model.%EC%BB%A8%ED%8B%B0%EB%84%A8%ED%83%88+10%EC%84%B8%EB%8C%80.))))' },
				]
			},
			{
				value: 'MKC', key: '(C.CarType.N._.(C.Manufacturer.%EB%A7%81%EC%BB%A8._.(C.ModelGroup.MKC._.Model.MKC.))))',
			},
		]
	},
	{
		value: 'Maserati', key: '(C.CarType.N._.Manufacturer.%EB%A7%88%EC%84%B8%EB%9D%BC%ED%8B%B0.))',
		models: [
			{
				value: 'Ghibli', key: '(C.CarType.N._.(C.Manufacturer.%EB%A7%88%EC%84%B8%EB%9D%BC%ED%8B%B0._.(C.ModelGroup.%EA%B8%B0%EB%B8%94%EB%A6%AC._.Model.%EA%B8%B0%EB%B8%94%EB%A6%AC.))))',
			},
			{
				value: 'Quattroporte', key: '(C.CarType.N._.(C.Manufacturer.%EB%A7%88%EC%84%B8%EB%9D%BC%ED%8B%B0._.(C.ModelGroup.%EC%BD%B0%ED%8A%B8%EB%A1%9C%ED%8F%AC%EB%A5%B4%ED%85%8C._.Model.%EC%BD%B0%ED%8A%B8%EB%A1%9C%ED%8F%AC%EB%A5%B4%ED%85%8C.))))',
			},
			{
				value: 'Levante', key: '(C.CarType.N._.(C.Manufacturer.%EB%A7%88%EC%84%B8%EB%9D%BC%ED%8B%B0._.(C.ModelGroup.%EB%A5%B4%EB%B0%98%EB%96%BC._.Model.%EB%A5%B4%EB%B0%98%EB%96%BC.))))',
			},
			{
				value: 'Gran Turismo', key: '(C.CarType.N._.(C.Manufacturer.%EB%A7%88%EC%84%B8%EB%9D%BC%ED%8B%B0._.(C.ModelGroup.%EA%B7%B8%EB%9E%80%ED%88%AC%EB%A6%AC%EC%8A%A4%EB%AA%A8._.Model.%EA%B7%B8%EB%9E%80%ED%88%AC%EB%A6%AC%EC%8A%A4%EB%AA%A8.))))',
			},
		]
	},
	// {
	// 	value: 'Mazda', key: '(C.CarType.N._.Manufacturer.%EB%A7%88%EC%AF%94%EB%8B%A4.))',
	// 	models: [
	// 		{
	// 			value: 'CX-9', key: '(C.CarType.N._.(C.Manufacturer.%EB%A7%88%EC%AF%94%EB%8B%A4._.(C.ModelGroup.CX-9._.Model.CX-9.))))',
	// 		},
	// 		{
	// 			value: 'MX-5 MIATA', key: '(C.CarType.N._.(C.Manufacturer.%EB%A7%88%EC%AF%94%EB%8B%A4._.(C.ModelGroup.MX-5+MIATA._.Model.MX-5+MIATA.))))',
	// 		},
	// 	]
	// },
	{
		value: 'McLaren', key: '(C.CarType.N._.Manufacturer.%EB%A7%A5%EB%9D%BC%EB%A0%8C.))',
		models: [
			{
				value: '720S', key: '(C.CarType.N._.(C.Manufacturer.%EB%A7%A5%EB%9D%BC%EB%A0%8C._.(C.ModelGroup.720S._.Model.720S.))))',
			},
		]
	},
	{
		value: 'MERCEDES BENZ', key: '(C.CarType.N._.Manufacturer.%EB%B2%A4%EC%B8%A0.))',
		models: [
			{
				value: 'E-Class W213', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.E-%ED%81%B4%EB%9E%98%EC%8A%A4._.Model.E-%ED%81%B4%EB%9E%98%EC%8A%A4+W213.))))',
			},
			{
				value: 'G-Class', key: '',
				subModels: [
					{ value: 'G-Class W463b', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.G-%ED%81%B4%EB%9E%98%EC%8A%A4+(G%EB%B0%94%EA%B2%90_)._.Model.G-%ED%81%B4%EB%9E%98%EC%8A%A4+W463b.))))' },
					{ value: 'G-Класс W463', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.G-%ED%81%B4%EB%9E%98%EC%8A%A4+(G%EB%B0%94%EA%B2%90_)._.Model.G-%ED%81%B4%EB%9E%98%EC%8A%A4+W463.))))' },
				]
			},
			{
				value: 'S-Class', key: '',
				subModels: [
					{ value: 'S-Class W223', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.S-%ED%81%B4%EB%9E%98%EC%8A%A4._.Model.S-%ED%81%B4%EB%9E%98%EC%8A%A4+W223.))))' },
					{ value: 'S-Class W222', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.S-%ED%81%B4%EB%9E%98%EC%8A%A4._.Model.S-%ED%81%B4%EB%9E%98%EC%8A%A4+W222.))))' },
				]
			},
			{
				value: 'C-Class', key: '',
				subModels: [
					{ value: 'C-Class W206', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.C-%ED%81%B4%EB%9E%98%EC%8A%A4._.Model.C-%ED%81%B4%EB%9E%98%EC%8A%A4+W206.))))' },
					{ value: 'C-Class W205', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.C-%ED%81%B4%EB%9E%98%EC%8A%A4._.Model.C-%ED%81%B4%EB%9E%98%EC%8A%A4+W205.))))' },
					{ value: 'C-Class W222', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.C-%ED%81%B4%EB%9E%98%EC%8A%A4._.Model.C-%ED%81%B4%EB%9E%98%EC%8A%A4+W222.))))' },
				]
			},
			{
				value: 'A-Class', key: '',
				subModels: [
					{ value: 'A-Class W177', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.A-%ED%81%B4%EB%9E%98%EC%8A%A4._.Model.A-%ED%81%B4%EB%9E%98%EC%8A%A4+W177.))))' },
				]
			},
			{
				value: 'CLA-Class', key: '',
				subModels: [
					{ value: 'CLA-Class C118', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.CLA-%ED%81%B4%EB%9E%98%EC%8A%A4._.Model.CLA-%ED%81%B4%EB%9E%98%EC%8A%A4+C118.))))' },
				]
			},
			{
				value: 'EQB X243', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.EQB._.Model.EQB+X243.))))',
			},
			{
				value: 'EQC N293', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.EQC._.Model.EQC+N293.))))',
			},
			{
				value: 'Спринтер', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.%EC%8A%A4%ED%94%84%EB%A6%B0%ED%84%B0._.Model.%EC%8A%A4%ED%94%84%EB%A6%B0%ED%84%B0.))))',
			},
			{
				value: 'AMG GT', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.AMG+GT._.Model.AMG+GT.))))',
			},
			{
				value: 'EQA-Class', key: '',
				subModels: [
					{ value: 'EQA H243', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.EQA._.Model.EQA+H243.))))' },
				]
			},
			{ value: 'GLC-Class X253', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.GLC-%ED%81%B4%EB%9E%98%EC%8A%A4._.Model.GLC-%ED%81%B4%EB%9E%98%EC%8A%A4+X253.))))' },
			{ value: 'CLS-Class C257', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.CLS-%ED%81%B4%EB%9E%98%EC%8A%A4._.Model.CLS-%ED%81%B4%EB%9E%98%EC%8A%A4+C257.))))' },
			{ value: 'GLE-class W167', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.GLE-%ED%81%B4%EB%9E%98%EC%8A%A4._.Model.GLE-%ED%81%B4%EB%9E%98%EC%8A%A4+W167.))))' },
			{ value: 'GLS-class X167', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%EC%B8%A0._.(C.ModelGroup.GLS-%ED%81%B4%EB%9E%98%EC%8A%A4._.Model.GLS-%ED%81%B4%EB%9E%98%EC%8A%A4+X167.))))' },
		]
	},
	{
		value: 'Bentley', key: '(C.CarType.N._.Manufacturer.%EB%B2%A4%ED%8B%80%EB%A6%AC.))',
		models: [
			{
				value: 'Bentayga', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%ED%8B%80%EB%A6%AC._.(C.ModelGroup.%EB%B2%A4%ED%85%8C%EC%9D%B4%EA%B0%80._.Model.%EB%B2%A4%ED%85%8C%EC%9D%B4%EA%B0%80.))))',
			},
			{
				value: 'Continental GT 3rd Generation', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%ED%8B%80%EB%A6%AC._.(C.ModelGroup.%EC%BB%A8%ED%8B%B0%EB%84%A8%ED%83%88._.Model.%EC%BB%A8%ED%8B%B0%EB%84%A8%ED%83%88+GT+3%EC%84%B8%EB%8C%80.))))',
			},
			{
				value: 'Flying Spur 3rd Generation ', key: '(C.CarType.N._.(C.Manufacturer.%EB%B2%A4%ED%8B%80%EB%A6%AC._.(C.ModelGroup.%ED%94%8C%EB%9D%BC%EC%9E%89%EC%8A%A4%ED%8D%BC._.Model.%ED%94%8C%EB%9D%BC%EC%9E%89%EC%8A%A4%ED%8D%BC+3%EC%84%B8%EB%8C%80.))))',
			},
		]
	},
	{
		value: 'Volvo', key: '(C.CarType.N._.Manufacturer.%EB%B3%BC%EB%B3%B4.))',
		models: [
			{
				value: 'XC60 2nd generation', key: '(C.CarType.N._.(C.Manufacturer.%EB%B3%BC%EB%B3%B4._.(C.ModelGroup.XC60._.Model.XC60+2%EC%84%B8%EB%8C%80.))))',
			},
			{
				value: 'XC90 2nd generation', key: '(C.CarType.N._.(C.Manufacturer.%EB%B3%BC%EB%B3%B4._.(C.ModelGroup.XC90._.Model.XC90+2%EC%84%B8%EB%8C%80.))))',
			},
			{
				value: 'S90', key: '(C.CarType.N._.(C.Manufacturer.%EB%B3%BC%EB%B3%B4._.(C.ModelGroup.S90._.Model.S90.))))',
			},
			{
				value: 'S60 3rd generation', key: '(C.CarType.N._.(C.Manufacturer.%EB%B3%BC%EB%B3%B4._.(C.ModelGroup.S60._.Model.S60+3%EC%84%B8%EB%8C%80.))))',
			},
			{
				value: 'V40', key: '(C.CarType.N._.(C.Manufacturer.%EB%B3%BC%EB%B3%B4._.(C.ModelGroup.V40._.Model.V40.))))',
			},
		]
	},
	{
		value: 'Audi', key: '(C.CarType.N._.Manufacturer.%EC%95%84%EC%9A%B0%EB%94%94.))',
		models: [
			{
				value: 'A6 (C8)', key: '(C.CarType.N._.(C.Manufacturer.%EC%95%84%EC%9A%B0%EB%94%94._.(C.ModelGroup.A6._.Model.A6+(C8_).))))',
			},
			{
				value: 'A4 (B9)', key: '(C.CarType.N._.(C.Manufacturer.%EC%95%84%EC%9A%B0%EB%94%94._.(C.ModelGroup.A4._.Model.A4+(B9_).))))',
			},
			{
				value: 'A7 (4K)', key: '(C.CarType.N._.(C.Manufacturer.%EC%95%84%EC%9A%B0%EB%94%94._.(C.ModelGroup.A7._.Model.A7+(4K_).))))',
			},
			{
				value: 'A8 (D5)', key: '(C.CarType.N._.(C.Manufacturer.%EC%95%84%EC%9A%B0%EB%94%94._.(C.ModelGroup.A8._.Model.A8+(D5_).))))',
			},
			{
				value: 'Q5 (FY) ', key: '(C.CarType.N._.(C.Manufacturer.%EC%95%84%EC%9A%B0%EB%94%94._.(C.ModelGroup.Q5._.Model.Q5+(FY_).))))',
			},
			{
				value: 'A3', key: '',
				subModels: [
					{ value: 'A3 (8Y)', key: '(C.CarType.N._.(C.Manufacturer.%EC%95%84%EC%9A%B0%EB%94%94._.(C.ModelGroup.A3._.Model.A3+(8Y_).))))' },
					{ value: 'New A3', key: '(C.CarType.N._.(C.Manufacturer.%EC%95%84%EC%9A%B0%EB%94%94._.(C.ModelGroup.A3._.Model.%EB%89%B4+A3.))))' }
				]
			},
		]
	},
	{
		value: 'Aston Martin', key: '(C.CarType.N._.Manufacturer.%EC%95%A0%EC%8A%A4%ED%84%B4%EB%A7%88%ED%8B%B4.))',
		models: [
			{
				value: 'Vantage 2nd Generation', key: '(C.CarType.N._.(C.Manufacturer.%EC%95%A0%EC%8A%A4%ED%84%B4%EB%A7%88%ED%8B%B4._.(C.ModelGroup.%EB%B0%B4%ED%8B%B0%EC%A7%80._.Model.%EB%B0%B4%ED%8B%B0%EC%A7%80+2%EC%84%B8%EB%8C%80.))))',
			},
			{
				value: 'DBX', key: '(C.CarType.N._.(C.Manufacturer.%EC%95%A0%EC%8A%A4%ED%84%B4%EB%A7%88%ED%8B%B4._.(C.ModelGroup.DBX._.Model.DBX.))))',
			},
		]
	},
	{
		value: 'Infinity', key: '(C.CarType.N._.Manufacturer.%EC%9D%B8%ED%94%BC%EB%8B%88%ED%8B%B0.))',
		models: [
			{
				value: 'Q50', key: '(C.CarType.N._.(C.Manufacturer.%EC%9D%B8%ED%94%BC%EB%8B%88%ED%8B%B0._.(C.ModelGroup.Q50._.Model.Q50.))))',
			},
			{
				value: 'Q30', key: '(C.CarType.N._.(C.Manufacturer.%EC%9D%B8%ED%94%BC%EB%8B%88%ED%8B%B0._.(C.ModelGroup.Q30._.Model.Q30.))))',
			},
			{
				value: 'Q60', key: '(C.CarType.N._.(C.Manufacturer.%EC%9D%B8%ED%94%BC%EB%8B%88%ED%8B%B0._.(C.ModelGroup.Q60._.Model.Q60.))))',
			},
			{
				value: 'Q70', key: '(C.CarType.N._.(C.Manufacturer.%EC%9D%B8%ED%94%BC%EB%8B%88%ED%8B%B0._.(C.ModelGroup.Q70._.Model.Q70.))))',
			},
		]
	},
	{
		value: 'Jaguar', key: '(C.CarType.N._.Manufacturer.%EC%9E%AC%EA%B7%9C%EC%96%B4.))',
		models: [
			{
				value: 'XF (X260)', key: '(C.CarType.N._.(C.Manufacturer.%EC%9E%AC%EA%B7%9C%EC%96%B4._.(C.ModelGroup.XF._.Model.XF+(X260_).))))',
			},
			{
				value: 'XE', key: '(C.CarType.N._.(C.Manufacturer.%EC%9E%AC%EA%B7%9C%EC%96%B4._.(C.ModelGroup.XE._.Model.XE.))))',
			},
			{
				value: 'All New XJ', key: '(C.CarType.N._.(C.Manufacturer.%EC%9E%AC%EA%B7%9C%EC%96%B4._.(C.ModelGroup.XJ._.Model.All+New+XJ.))))',
			},
			{
				value: 'F-PACE', key: '(C.CarType.N._.(C.Manufacturer.%EC%9E%AC%EA%B7%9C%EC%96%B4._.(C.ModelGroup.F-PACE._.Model.F-PACE.))))',
			},
		]
	},
	{
		value: 'Jeep', key: '(C.CarType.N._.Manufacturer.%EC%A7%80%ED%94%84.))',
		models: [
			{
				value: 'Wrangler (JL)', key: '(C.CarType.N._.(C.Manufacturer.%EC%A7%80%ED%94%84._.(C.ModelGroup.%EB%9E%AD%EA%B8%80%EB%9F%AC._.Model.%EB%9E%AD%EA%B8%80%EB%9F%AC+(JL_).))))',
			},
			{
				value: 'Renegade', key: '(C.CarType.N._.(C.Manufacturer.%EC%A7%80%ED%94%84._.(C.ModelGroup.%EB%A0%88%EB%8B%88%EA%B2%8C%EC%9D%B4%EB%93%9C._.Model.%EB%A0%88%EB%8B%88%EA%B2%8C%EC%9D%B4%EB%93%9C.))))',
			},
			// {
			// 	value: 'Grand Cherokee', key: '(C.CarType.N._.(C.Manufacturer.%EC%A7%80%ED%94%84._.(C.ModelGroup.%EC%B2%B4%EB%A1%9C%ED%82%A4._.Model.%EA%B7%B8%EB%9E%9C%EB%93%9C+%EC%B2%B4%EB%A1%9C%ED%82%A4+L.))))',
			// },
			{
				value: 'Compass 2nd Generation', key: '(C.CarType.N._.(C.Manufacturer.%EC%A7%80%ED%94%84._.(C.ModelGroup.%EC%BB%B4%ED%8C%A8%EC%8A%A4._.Model.%EC%BB%B4%ED%8C%A8%EC%8A%A4+2%EC%84%B8%EB%8C%80.))))',
			},
		]
	},
	{
		value: 'Cadillac', key: '(C.CarType.N._.Manufacturer.%EC%BA%90%EB%94%9C%EB%9D%BD.))',
		models: [
			{ value: 'Escalade 5th generation', key: '(C.CarType.N._.(C.Manufacturer.%EC%BA%90%EB%94%9C%EB%9D%BD._.(C.ModelGroup.%EC%97%90%EC%8A%A4%EC%BB%AC%EB%A0%88%EC%9D%B4%EB%93%9C._.Model.%EC%97%90%EC%8A%A4%EC%BB%AC%EB%A0%88%EC%9D%B4%EB%93%9C+5%EC%84%B8%EB%8C%80.))))' },
			{ value: 'CT6', key: '(C.CarType.N._.(C.Manufacturer.%EC%BA%90%EB%94%9C%EB%9D%BD._.(C.ModelGroup.CT6._.Model.CT6.))))' },
		]
	},
	{
		value: 'Tesla', key: '(C.CarType.N._.Manufacturer.%ED%85%8C%EC%8A%AC%EB%9D%BC.))',
		models: [
			{ value: 'Model 3', key: '(C.CarType.N._.(C.Manufacturer.%ED%85%8C%EC%8A%AC%EB%9D%BC._.(C.ModelGroup.%EB%AA%A8%EB%8D%B8+3._.Model.%EB%AA%A8%EB%8D%B8+3.))))' },
			{ value: 'Model Y', key: '(C.CarType.N._.(C.Manufacturer.%ED%85%8C%EC%8A%AC%EB%9D%BC._.(C.ModelGroup.%EB%AA%A8%EB%8D%B8+Y._.Model.%EB%AA%A8%EB%8D%B8+Y.))))' },
			{ value: 'Model X', key: '(C.CarType.N._.(C.Manufacturer.%ED%85%8C%EC%8A%AC%EB%9D%BC._.(C.ModelGroup.%EB%AA%A8%EB%8D%B8+X._.Model.%EB%AA%A8%EB%8D%B8+X.))))' },
		]
	},
	{
		value: 'Ferrari', key: '(C.CarType.N._.Manufacturer.%ED%8E%98%EB%9D%BC%EB%A6%AC.))',
		models: [
			{ value: 'F8 Tributo', key: '(C.CarType.N._.(C.Manufacturer.%ED%8E%98%EB%9D%BC%EB%A6%AC._.(C.ModelGroup.F8._.Model.F8+%ED%8A%B8%EB%A6%AC%EB%B7%B0%ED%86%A0.))))' },
			{ value: 'F8 Spider', key: '(C.CarType.N._.(C.Manufacturer.%ED%8E%98%EB%9D%BC%EB%A6%AC._.(C.ModelGroup.F8._.Model.F8+%EC%8A%A4%ED%8C%8C%EC%9D%B4%EB%8D%94.))))' },
		]
	},
	{
		value: 'Ford', key: '(C.CarType.N._.Manufacturer.%ED%8F%AC%EB%93%9C.))',
		models: [
			{ value: 'Explorer 6th generation ', key: '(C.CarType.N._.(C.Manufacturer.%ED%8F%AC%EB%93%9C._.(C.ModelGroup.%EC%9D%B5%EC%8A%A4%ED%94%8C%EB%A1%9C%EB%9F%AC._.Model.%EC%9D%B5%EC%8A%A4%ED%94%8C%EB%A1%9C%EB%9F%AC+6%EC%84%B8%EB%8C%80.))))' },
			{ value: 'Mustang', key: '(C.CarType.N._.(C.Manufacturer.%ED%8F%AC%EB%93%9C._.(C.ModelGroup.%EB%A8%B8%EC%8A%A4%ED%83%B1._.Model.%EB%A8%B8%EC%8A%A4%ED%83%B1.))))' },
			{ value: 'New Taurus', key: '(C.CarType.N._.(C.Manufacturer.%ED%8F%AC%EB%93%9C._.(C.ModelGroup.%ED%86%A0%EB%9F%AC%EC%8A%A4._.Model.%EB%89%B4+%ED%86%A0%EB%9F%AC%EC%8A%A4.))))' },
		]
	},
	{
		value: 'Porsche', key: '(C.CarType.N._.Manufacturer.%ED%8F%AC%EB%A5%B4%EC%89%90.))',
		models: [
			{ value: 'Cayenne', key: '(C.CarType.N._.(C.Manufacturer.%ED%8F%AC%EB%A5%B4%EC%89%90._.(C.ModelGroup.%EC%B9%B4%EC%9D%B4%EC%97%94._.Model.%EC%B9%B4%EC%9D%B4%EC%97%94+(PO536_).))))' },
			{ value: 'Panamera', key: '(C.CarType.N._.(C.Manufacturer.%ED%8F%AC%EB%A5%B4%EC%89%90._.(C.ModelGroup.%ED%8C%8C%EB%82%98%EB%A9%94%EB%9D%BC._.Model.%ED%8C%8C%EB%82%98%EB%A9%94%EB%9D%BC+(971_).))))' },
			{ value: '911', key: '(C.CarType.N._.(C.Manufacturer.%ED%8F%AC%EB%A5%B4%EC%89%90._.(C.ModelGroup.911._.Model.911+(992_).))))' },
		]
	},
	{
		value: 'Volkswagen', key: '(C.CarType.N._.Manufacturer.%ED%8F%AD%EC%8A%A4%EB%B0%94%EA%B2%90.)',
		models: [
			{ value: 'Tiguan 2nd generation', key: '(C.CarType.N._.(C.Manufacturer.%ED%8F%AD%EC%8A%A4%EB%B0%94%EA%B2%90._.(C.ModelGroup.%ED%8B%B0%EA%B5%AC%EC%95%88._.Model.%ED%8B%B0%EA%B5%AC%EC%95%88+2%EC%84%B8%EB%8C%80.))))' },
			{ value: 'Golf 7th generation', key: '(C.CarType.N._.(C.Manufacturer.%ED%8F%AD%EC%8A%A4%EB%B0%94%EA%B2%90._.(C.ModelGroup.%EA%B3%A8%ED%94%84._.Model.%EA%B3%A8%ED%94%84+7%EC%84%B8%EB%8C%80.))))' },
			{ value: 'Passat GT (B8)', key: '(C.CarType.N._.(C.Manufacturer.%ED%8F%AD%EC%8A%A4%EB%B0%94%EA%B2%90._.(C.ModelGroup.%ED%8C%8C%EC%82%AC%ED%8A%B8._.Model.%ED%8C%8C%EC%82%AC%ED%8A%B8+GT(B8_).))))' },
			{ value: 'Jetta 7th Generation ', key: '(C.CarType.N._.(C.Manufacturer.%ED%8F%AD%EC%8A%A4%EB%B0%94%EA%B2%90._.(C.ModelGroup.%EC%A0%9C%ED%83%80._.Model.%EC%A0%9C%ED%83%80+7%EC%84%B8%EB%8C%80.))))' },
		]
	},
	{
		value: 'Honda', key: '(C.CarType.N._.Manufacturer.%ED%98%BC%EB%8B%A4.))',
		models: [
			{ value: 'Accord 10th generation', key: '(C.CarType.N._.(C.Manufacturer.%ED%98%BC%EB%8B%A4._.(C.ModelGroup.%EC%96%B4%EC%BD%94%EB%93%9C._.Model.%EC%96%B4%EC%BD%94%EB%93%9C+10%EC%84%B8%EB%8C%80.))))' },
			{ value: 'Pilot 3rd Generation', key: '(C.CarType.N._.(C.Manufacturer.%ED%98%BC%EB%8B%A4._.(C.ModelGroup.%ED%8C%8C%EC%9D%BC%EB%9F%BF._.Model.%ED%8C%8C%EC%9D%BC%EB%9F%BF+3%EC%84%B8%EB%8C%80.))))' },
			{ value: 'Civic', key: '(C.CarType.N._.(C.Manufacturer.%ED%98%BC%EB%8B%A4._.(C.ModelGroup.%EC%8B%9C%EB%B9%85._.Model.%EC%8B%9C%EB%B9%85.))))' },
			{ value: 'CR-V 5th generation', key: '(C.CarType.N._.(C.Manufacturer.%ED%98%BC%EB%8B%A4._.(C.ModelGroup.CR-V._.Model.CR-V+5%EC%84%B8%EB%8C%80.))))' },
			{ value: 'Odyssey', key: '(C.CarType.N._.(C.Manufacturer.%ED%98%BC%EB%8B%A4._.(C.ModelGroup.%EC%98%A4%EB%94%A7%EC%84%B8%EC%9D%B4._.Model.%EC%98%A4%EB%94%A7%EC%84%B8%EC%9D%B4.))))' },
		]
	},
];

