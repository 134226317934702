import React from "react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage(): JSX.Element {
  const error = useRouteError();
  console.error(error);
  return (
    <div id="error-page">
      <h1>Случилась ошибка в поиске страницы</h1>
      <p>Можете перезайти или очистить кэш для повторного поиска</p>
      <p>
        <i>{(error as any)?.statusText || (error as any)?.message}</i>
      </p>
    </div>
  );
}