import { action, makeAutoObservable } from "mobx";
import { fetchCarsList, fetchCurrency, fetchCurrentCar, fetchCurrentCarInspection } from "../../api";
import {
  makePersistable,
  stopPersisting
} from "mobx-persist-store";
import { create, persist } from 'mobx-persist'

export class CarsStore {
  carsList = [];
  filteredCars = [];
  carDetails = undefined;
  isLoading = true;
  itemsPerPage = 100;
  prevPage = 0;
  nextPage = 100;
  pageCount = null;
  selectedPage = 1
  isLoadingList = undefined;
  currency = null;
  @persist currencyLabel = null;
  @persist currentManufacture = null;
  @persist currentModel = null;
  currentCar = null;
  currentCarInspection = null;

  yearFrom = null;
  yearTo = null;

  @persist minPrice = null;
  @persist maxPrice = null;

  @persist fuelTypes = [];

  constructor() {
    makeAutoObservable(this);
    if (this.carsList.length === 0) {
      // this.loadCars(this.currentManufacture);
    }
    this.resetPages()
    this.loadCurrency('KRW', 'USD');

    makePersistable(this, {
      name: 'carsStore', properties:
        ['currencyLabel', 'currentManufacture', 'currentModel',
          'minPrice', 'maxPrice', 'fuelTypes',
        ], storage: localStorage
    }).then(
      action((persistStore) => {
        console.log(persistStore.isHydrated);
      })
    );

    this.clearAsyncStorage()
  }

  stopStore() {
    stopPersisting(this);
  }


  @action clearAsyncStorage = async () => {
    localStorage.clear();
  }

  @action setLoadingList = (isLoadingList) => (this.isLoadingList = isLoadingList);
  @action setPageCount = (pageCount) => (this.pageCount = pageCount);
  @action setLoadingCarsList = (carsList) => (this.carsList = carsList);
  @action setCurrency = (currency) => (this.currency = currency);
  @action setCurrencyLabel = (currencyLabel) => (this.currencyLabel = currencyLabel);
  @action setSelectedPage = (selectedPage) => {
    this.selectedPage = selectedPage
  };

  @action setCurrentManufacture = (currentManufacture) => (this.currentManufacture = currentManufacture);
  @action setCurrentModel = (currentModel) => (this.currentModel = currentModel);
  @action setCurrentCar = (currentCar) => (this.currentCar = currentCar);
  @action setCurrentCarInspection = (currentCarInspection) => (this.currentCarInspection = currentCarInspection);

  @action setYearFrom = (yearFrom) => (this.yearFrom = yearFrom);
  @action setYearTo = (yearTo) => (this.yearTo = yearTo);

  @action setMinPrice = (minPrice) => (this.minPrice = minPrice);
  @action setMaxPrice = (maxPrice) => (this.maxPrice = maxPrice);

  @action setFuelTypes = (fuelTypes) => (this.fuelTypes = fuelTypes);


  // @ts-ignore
  filterCars(filter: any) {
    if (filter) {
      const result = this.carsList.filter((car: any) =>
        car.brand
          ? car.brand.toUpperCase().includes(filter.toUpperCase())
          : false
      );
      this.filteredCars = result;
    } else {
      this.filteredCars = this.carsList;
    }
  }
  @action stopLoading() {
    this.isLoading = false;
  }

  @action loadCars = async () => {
    this.setLoadingList(true);

    var result;
    result = await fetchCarsList(this.itemsPerPage, this.nextPage, this.currentManufacture?.key,
      this.currentModel?.key, this.yearFrom, this.yearTo, this.minPrice, this.maxPrice, this.fuelTypes);

    this.setLoadingCarsList(result.SearchResults);
    this.setPageCount(result.Count);

    if (this.pageCount < 100) {
      this.nextPage = 0
      result = await fetchCarsList(this.itemsPerPage, this.nextPage, this.currentManufacture?.key,
        this.currentModel?.key, this.yearFrom, this.yearTo, this.minPrice, this.maxPrice, this.fuelTypes);
      this.setLoadingCarsList(result.SearchResults);
      this.setPageCount(result.Count);
    }

    this.setLoadingList(false);

    return result.SearchResults
  };


  @action changePages() {
    // if (this.prevPage <= 0) {
    var page;
    if (this.selectedPage == 0) {
      page = 1
    } else {
      page = this.selectedPage
    }

    this.prevPage = this.itemsPerPage * this.selectedPage - this.itemsPerPage
    // }
    this.nextPage = this.itemsPerPage * this.selectedPage
  }
  @action resetPages() {
    // if (this.prevPage <= 0) {
    this.prevPage = 0
    // }
    this.nextPage = 100
    this.selectedPage = 1
  }
  @action getCurrentCar = async (carId) => {
    this.setLoadingList(true);
    const result = await fetchCurrentCar(carId);
    this.setCurrentCar(result);
    this.setLoadingList(false);
  };

  @action getCurrentCarInspection = async (carId) => {
    this.setLoadingList(true);
    const result = await fetchCurrentCarInspection(carId);
    this.setCurrentCarInspection(result);
    this.setLoadingList(false);
  };

  @action loadCurrency = async (currencyFrom: string, currencyTo: string) => {
    const result = await fetchCurrency(currencyFrom, currencyTo);
    this.setCurrency(result?.result);

    return result?.result
  };

  @action loadCurrencyUSD = async (currencyFrom: string, currencyTo: string) => {
    const result = await fetchCurrency(currencyFrom, currencyTo);
    return result?.result
  };
}
const hydrate = create({
  storage: localStorage,   // or AsyncStorage in react-native.
  // default: localStorage
  jsonify: false  // if you use AsyncStorage, here shoud be true
  // default: true
})

export default new CarsStore();

// create the state
hydrate('car', CarsStore).then(() => console.log('carsStore has been hydrated'))