import React from "react";
import { StoreProvider } from "./common/store";
import Layout from "./layouts/Layout";
import Catalog from "./pages/Catalog";
import Car from "./pages/Car";
import Landing from "./pages/Landing";
import LandingMobile from "./pages/LandingMobile";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "pages/Error";
import translate from 'translate'

translate.engine = "yandex";
translate.cache = 100000000
translate.key = process.env.REACT_APP_YANDEX;


const App = () => {

  const router = createBrowserRouter([
    {
      element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Landing />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/mobile",
          element: <LandingMobile />,
        },
        {
          path: "/catalog",
          element: <Catalog />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/car/:key",
          element: <Car />,
          errorElement: <ErrorPage />,
        },
      ]
    }
  ]);

  return (
    <React.StrictMode>
      <StoreProvider>
        <RouterProvider router={router} />
      </StoreProvider>
    </React.StrictMode>
  );
};



export default App;
