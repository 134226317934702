import { Grid, Link, Box } from "@mui/material";
import React from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { AiOutlineWhatsApp, AiFillInstagram } from "react-icons/ai";
import VideoList from "./videoList";
import Stores from './Stores'
import Sliders from "components/Sliders";


export default function Landing() {
	return (
		<Grid className="common-home">
			<div className="container">
				<div className="row">
					<div id="content" className="col-sm-12">
						<div>
							<div className="container ">
								<Sliders />
							</div>
						</div>
						<div>
							<div className="container">
								<div className="about1" id="about1">
									<div className="turan">
										<div className="turan2"></div>
									</div>
									<div className="ab_txt">
										<div className="ab_txt2">
											<h1>Сайт по продаже автомобилей из Кореи</h1>
											<ul>
												<li>Прямая связь без посредников,поставка в любой регион РК</li>
												<li>Официальный договор,прозрачность платежей</li>
												<li>Индивидуальный подход к каждому клиенту</li>
												<li>Постоянное on-line информационное сопровождение</li>
												<li>Максимально удобные условия, низкие цены на перевозку при высоком уровне
													обслуживания Клиента.</li>
												<li>Для каждого нового клиента мы предоставляем брокерские аккаунты для растоможку.</li>
											</ul>
											<span>Turan-Auto – это надежный партнер, которому доверяют!</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="container">
								<div className="srav">
									<div className="srav_title">
										Почувствуй разницу покупки авто из Кореи
									</div>
									<div className="srav_item_wr">
										<div className="row">
											<div className="srav_country">
												<div className="col-md-6 col-xs-6">
													Казахстан
													<Grid item xs={6} sm={6} md={6} sx={{ width: { xs: 110, sm: 200, md: 300, lg: 400 } }} >
														<Box
															justifyContent={'center'}
															alignContent={'center'}
															component="img"
															sx={{ width: { xs: 110, sm: 200, md: 300, lg: 400 } }}
															alt="xponend.com zhasapp.com sultandelux"
															src={require('../assets/kzsonata.png')}
														/>
													</Grid>
												</div>
												<div className="col-md-6 col-xs-6">
													Корея
													<Grid item xs={6} sm={6} md={6} sx={{ width: { xs: 120, sm: 200, md: 300, lg: 400 } }} >
														<Box
															justifyContent={'center'}
															alignContent={'center'}
															component="img"
															sx={{ width: { xs: 110, sm: 200, md: 300, lg: 400 } }}
															display='block'
															alt="xponend.com zhasapp.com sultandelux"
															src={require('../assets/kosonata.png')}
														/>
													</Grid>
												</div>
												<div className="clearfix"></div>
											</div>
											<div className="srav_name">
												<div className="col-md-6 col-xs-6">
													Hyndai Sonata 2020
												</div>
												<div className="col-md-6 col-xs-6">
													Hyndai Sonata 2020
												</div>
												<div className="clearfix"></div>
											</div>
											<div className="srav_price">
												<div className="col-md-6 col-xs-6">
													14.000.000 тг.
												</div>
												<div className="col-md-6 col-xs-6 limegreen">
													12.000.000 тг.
												</div>
												<div className="clearfix"></div>
											</div>
											<div className="srav_info">
												<div className="srav_info_title">
													<div className="col-md-6 col-xs-12">
														Пробег
													</div>
													<div className="col-md-6 col-xs-6 hidden-xs">
														Пробег
													</div>
													<div className="clearfix"></div>
												</div>
												<div className="col-md-6 col-xs-6">
													<span className="x">50.000 км</span>
												</div>
												<div className="col-md-6 col-xs-6">
													<span className="ok">25.000 км</span>
												</div>
												<div className="clearfix"></div>
												<div className="srav_info_title">
													<div className="col-md-6 col-xs-12">
														Владельцы
													</div>
													<div className="col-md-6 col-xs-6 hidden-xs">
														Владельцы
													</div>
													<div className="clearfix"></div>
												</div>
												<div className="col-md-6 col-xs-6">
													<span className="x">2-4 владельца</span>
												</div>
												<div className="col-md-6 col-xs-6">
													<span className="ok">1-2 владельца</span>
												</div>
												<div className="clearfix"></div>
												<div className="srav_info_title">
													<div className="col-md-6 col-xs-12">
														Тип авто
													</div>
													<div className="col-md-6 col-xs-6 hidden-xs">
														Тип авто
													</div>
													<div className="clearfix"></div>
												</div>
												<div className="col-md-6 col-xs-6">
													<span className="x">Бензин, Эконом-класс, Минимальная комплектация</span>
												</div>
												<div className="col-md-6 col-xs-6">
													<span className="ok">Бензин, Богатая комплектация, Огромный выбор
														вариантов</span>
												</div>
												<div className="clearfix"></div>
												<div className="srav_info_title">
													<div className="col-md-6 col-xs-12">
														История
													</div>
													<div className="col-md-6 col-xs-6 hidden-xs">
														История
													</div>
													<div className="clearfix"></div>
												</div>
												<div className="col-md-6 col-xs-6">
													<span className="x">Плохие дороги, После такси, Некачественное
														обслуживание</span>
												</div>
												<div className="col-md-6 col-xs-6">
													<span className="ok">Отличные дороги, Хорошие погодные условия</span>
												</div>
												<div className="clearfix"></div>
												<div className="srav_info_title">
													<div className="col-md-6 col-xs-12">
														Необходимый ремонт
													</div>
													<div className="col-md-6 col-xs-6 hidden-xs">
														Необходимый ремонт
													</div>
													<div className="clearfix"></div>
												</div>
												<div className="col-md-6 col-xs-6">
													<span className="x"> Полное обслуживание с заменой/профилактикой основных
														узлов</span>
												</div>
												<div className="col-md-6 col-xs-6">
													<span className="ok">Легкий удар навесных элементов. По финалу состояние нового
														авто</span>
												</div>
												<div className="clearfix"></div>
											</div>
											<div className="srav_ask buttonHidden">
												<div className="col-md-6 col-xs-12">
													Хотите узнать больше?
													<span>Оставьте заявку на бесплатную консультацию и наш менеджер ответит на
														все ваши вопросы!</span>
												</div><a href="https://wa.me/7002019020">
													<div className="col-md-6 col-xs-12">
														<div className="srav_btn feedback">Оставить заявку</div>
													</div>
												</a>
												<div className="clearfix"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="ocf-container ocf-custom-1 ocf-theme-light ocf-mobile-1 ocf-mobile-right ocf-horizontal ocf-top"
							id="ocf-module-1">
							<VideoList />
							<link rel="stylesheet" href="catalog/view/theme/default/stylesheet/ocfilter/ocf.cache.992.4982315955.css" />
							<div>
							</div>
							<div>
								<div className="container">
									<section id="section5">
										<div className="title_block">
											Схема подбора, покупки и транспортировки Корейских авто в РК
											<span>
												Процесс покупки и доставки авто из Кореи — это не 3 дня, поэтому мы доносим до
												каждого клиента, какой путь ждёт его будущее авто.
											</span>
										</div>
										<div className="car"></div>
										<div id="point1" className="point_trigger"></div>
										<div className="point point1">
											<div className="col-md-5 col-sm-5 col-xs-8">
												<div className="point-text text-left pull-left">
													<b>Подбор автомобиля.</b> Подробная консультация и подбор авто со специалистом
												</div>
												<div className="point-number pull-left">
													1
												</div>
											</div>
											<div className="col-md-2 col-sm-2 hidden-xs"></div>
											<div className="col-md-5 col-sm-5 hidden-xs"></div>
											<div className="clearfix"></div>
										</div>
										<div id="point2" className="point_trigger"></div>
										<div className="point point2" id="point2">
											<div className="col-md-5 col-sm-5 hidden-xs"></div>
											<div className="col-md-2 col-sm-2 hidden-xs"></div>
											<div className="col-md-5 col-sm-5 col-xs-8">
												<div className="point-text text-left pull-right">
													<b>Договор.</b>&nbsp; Согласование конечной стоимости покупки и срока доставки
													автомобиля.
												</div>
												<div className="point-number pull-right">
													2
												</div>
											</div>
											<div className="clearfix"></div>
										</div>
										<div id="point3" className="point_trigger"></div>
										<div className="point point3">
											<div className="col-md-5 col-sm-5 col-xs-8">
												<div className="point-text text-left pull-left">
													<b>Проверка авто.</b> Анализируем историю выбранной машины, делаем обзор машины
													и снимаем видео.
												</div>
												<div className="point-number pull-left">
													3
												</div>
											</div>
											<div className="col-md-2 col-sm-2 hidden-xs"></div>
											<div className="col-md-5 col-sm-5 hidden-xs"></div>
											<div className="clearfix"></div>
										</div>
										<div id="point4" className="point_trigger"></div>
										<div className="point point4">
											<div className="col-md-5 col-sm-5 hidden-xs"></div>
											<div className="col-md-2 col-sm-2 hidden-xs"></div>
											<div className="col-md-5 col-sm-5 col-xs-8">
												<div className="point-text text-left pull-right">
													<b>Покупка авто.</b> Вы самостоятельно оплачиваете в банке стоимость машины по
													инвойсам
												</div>
												<div className="point-number pull-right">
													4
												</div>
											</div>
											<div className="clearfix"></div>
										</div>
										<div id="point5" className="point_trigger"></div>
										<div className="point point5">
											<div className="col-md-5 col-sm-5 col-xs-8">
												<div className="point-text text-left pull-left">
													<b>Доставка.</b> Доставка в Казахстан 25-45 дней.
												</div>
												<div className="point-number pull-left">
													5
												</div>
											</div>
											<div className="col-md-2 col-sm-2 hidden-xs"></div>
											<div className="col-md-5 col-sm-5 hidden-xs"></div>
											<div className="clearfix"></div>
										</div>
										<div id="point6" className="point_trigger"></div>
										<div className="point point6">
											<div className="col-md-5 col-sm-5 hidden-xs"></div>
											<div className="col-md-2 col-sm-2 hidden-xs"></div>
											<div className="col-md-5 col-sm-5 col-xs-8">
												<div className="point-text text-left pull-right">
													<b>Растаможка.</b> Брокеры помогут сделать полную таможенную очистку
													(растаможку) вашего автомобиля.
												</div>
												<div className="point-number pull-right">
													6
												</div>
											</div>
											<div className="clearfix"></div>
										</div>
										<div id="point7" className="point_trigger"></div>
										<div className="point point7" id="point7">
											<div className="col-md-5 col-sm-5 col-xs-8">
												<div className="point-text text-left pull-left">
													<b>Сертификация и постановка на учет.</b>
													Брокеры оформляют сертификацию на Евро5. Последний этап—это регистрация в
													Сервисном центре МВД. После этого вы получаете тех паспорт и государственные
													номерные знаки.
												</div>
												<div className="point-number pull-left">
													7
												</div>
											</div>
											<div className="col-md-2 col-sm-2 hidden-xs"></div>
											<div className="col-md-5 col-sm-5 hidden-xs"></div>
											<div className="clearfix"></div>
										</div>
										<div className="point_footer buttonHidden">
											<span>Узнайте более подробнее! Получите консультацию.</span>
											<a href="https://wa.me/7002019020">
												<div className="srav_btn feedback">Получить консультацию</div></a>
										</div>
									</section>
								</div>
							</div>
							<Stores />
							<div>
								<div className="bg2">
									<div className="container">
										<div className="row">
											<div className="title_bg2">
												Открыто покажем как формируется цена авто из Кореи
											</div>
											<div className="bg2_tit">Услуги Turan-Auto</div>
											<div className="bg2_tit">Стоимость автомобиля из Кореи</div>
											<div className="bg2_txt">
												<p>* 100$ загрузка машины в контейнер из Кореи </p>
												<p>* 100$ Оформление экспортных документов на авто </p>
												<p>* 3100$ Доставка в Казахстан </p>
												<p>* 250$ Брокер </p>
												<p>* 600$ Сертификация </p>
												<p>* 100$ СВХ </p>
												<p>* От $1500 Растаможка зависит от машины </p>
												<p>* 300$ ИС </p>
												<p>* $0—3500$ Первичная регистрация - Авто до 2-х лет (20-21г) — $0  - 3 года (2021г) — 350$ - 4-5 года (2024) — 3450$ </p>
												{/* <p>* 600$ Услуги Turan auto</p> */}
											</div>
											<a className="bg2_btn buttonHidden" href="https://wa.me/7002019020" id="modal">Получить консультацию</a>
											<div className="light"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<footer className="buttonHidden">
					<Grid alignContent={"center"} alignItems={'center'} gap={2} mb={2} sx={{ display: { sm: 'none', xs: 'flex' }, flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
						<Link href="https://www.facebook.com/Turan-autokz-114567034467389" color={'inherit'} > <FaFacebookSquare size={22} /></Link>
						<Link href="https://instagram.com/top.auto.korea?igshid=MzRlODBiNWFlZA==" color={'inherit'}  ><AiFillInstagram size={22} /></Link>
						<Link href="https://wa.me/77002019020" color={'inherit'} ><AiOutlineWhatsApp size={22} /> </Link>
						{/* <Link href="tel:+821072953574" color="inherit" variant={'h4'}>+8 210 729 53574</Link> */}
					</Grid>
					<Link href="tel:+77002019020" color="inherit" ml={16} variant={'h4'} mt={2} sx={{ display: { sm: 'none', xs: 'flex' } }}> +7 700 201 9020</Link>
				</footer>
			</div>
		</Grid >
	)
}

